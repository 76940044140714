import { SET_ALL_RECONCILIATION } from "./reconciliationActionTypes";

const reconciliationDataState = {
    allReconciliation: []
}

const reconciliationData = (state = reconciliationDataState, action) => {
    switch (action.type) {
        case SET_ALL_RECONCILIATION:
            return [...action.data];
        default: return state
    }
}

export default reconciliationData