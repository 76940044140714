import { SET_ALL_CHANNEL_ORDERS } from "./channelOrdersActionTypes";

const allChannelOrdersData = []

const channelOrdersData = (state = allChannelOrdersData, action) => {
    switch (action.type) {
        case SET_ALL_CHANNEL_ORDERS:
            return  [...action.data];
        default: return state
    }
}

export default channelOrdersData

