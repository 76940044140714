import { all, fork, put, takeEvery } from "redux-saga/effects"
import { GET_ALL_RECONCILIATION, SET_ALL_RECONCILIATION } from './reconciliationActionTypes'

function* getAllReconciliation() {
    const response = yield fetch('https://api.couriero.in/weight-reconciliation/fetch', {
        method: 'POST',
        headers: {
            'auth-token': localStorage.getItem('token')
        }
    })

    const result = yield response.json()
    yield put({type: SET_ALL_RECONCILIATION, data: result})
}

function* watchGetAllReconciliation() {
    yield takeEvery(GET_ALL_RECONCILIATION, getAllReconciliation)
}

function* reconciliationSaga() {
    yield all([
        fork(watchGetAllReconciliation)
    ])
}

export default reconciliationSaga