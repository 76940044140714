import { all, fork, put, takeEvery } from "redux-saga/effects";
import { GET_USER_DETAILS, SET_USER_DETAILS } from "./userDetailsActionTypes";

function* getUserDetails() {
    const response = yield fetch('https://api.couriero.in/api/auth/get-user', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'auth-token': localStorage.getItem('token')
        }
    })
    const result = yield response.json()
    yield put({type: SET_USER_DETAILS, data: result})
}

function* watchGetUserDetails() {
    yield takeEvery(GET_USER_DETAILS, getUserDetails)
}

function* userDetailsSaga() {
    yield all([
        fork(watchGetUserDetails)
    ])
}

export default userDetailsSaga