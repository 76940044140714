import { SET_COD_REMITTANCE } from "./codActionTypes"

const codRemittanceState = []

const codRemittanceData = (state = codRemittanceState, action) => {
    switch(action.type) {
        case SET_COD_REMITTANCE:
            return [...action.data];
        default: return state
    }
}

export default codRemittanceData