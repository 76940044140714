import { SET_USER_DETAILS } from "./userDetailsActionTypes"

const allUserDetails = {}

const userDetails = (state =allUserDetails, action) => {
    switch(action.type) {
        case SET_USER_DETAILS:
            return action.data;
        default: return state
    }
}

export default userDetails