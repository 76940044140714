import React from 'react'
import Footer from './Footer'
import Navbar from './Navbar'
import Sidebar from './Sidebar'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';




const Layout = props => {
  return (
    <React.Fragment>
      <div className='flex flex-col  bg-white w-full overflow-x-hidden'>
      <ToastContainer />
        <div className='w-full fixed shadow-lg z-30 bg-white items-center justify-evenly md:justify-between flex h-14 '>
          <Navbar />
        </div>
        <div className='flex flex-row bg-white'>
          <div className='fixed w-14 md:w-2/12 hover:w-6/12 md:hover:w-2/12 left-0 bg-white h-full top-12 z-10 overflow-y-hidden shadow-lg'>
            <Sidebar />
          </div>
          <div className='overflow-auto pb-20 w-10/12 md:w-10/12 lg:w-10/12 z-20 h-full fixed right-0  mt-20 md:px-5'>
            <div className=' flex w-full'>{props.children}</div>
          </div>
        </div>
        <div className='' >
          <Footer />
        </div>
      </div>
    </React.Fragment>
  )
}

export default Layout;