import { SET_ALL_CHANNELS } from "./channelsActionTypes"

const allChannelsState = []

const channelsData = (state = allChannelsState, action) => {

    switch (action.type) {
        case SET_ALL_CHANNELS:
            return [...action.data];
        default: return state
    }

}

export default channelsData