import React, { useState } from 'react'
import Breadcrumb from '../../../components/common/Breadcrumb'
import shopifyLogo from '../../../Assets/Shopifylogo.png'





const ShopifyChannel = () => {

    const [shopifyStoreFields, setShopifyStoreFields] = useState({ channelName: 'Shopify', brandName: '', storeUrl: '', apiAccessToken: '', apiKey: '', apiSecret: '' })

   

    const inputOnChange = (e) => {
        setShopifyStoreFields({ ...shopifyStoreFields, [e.target.name]: e.target.value })
    }

    const shopifyOnSubmit = (e) => {
        e.preventDefault()

        const url = `https://api.couriero.in/shopify/auth?shop=${shopifyStoreFields.storeUrl}`

        window.open(url, "_blank", "noopener,noreferrer");

    }

    return (
        <React.Fragment>
            <div className='bg-white mb-12 w-full flex flex-col text-gray-600 overflow-y-auto'>
                <div className='w-full'>
                    <Breadcrumb breadcrumbItem="Shopify" title="Add Channel" />
                </div>
                <div className='flex flex-row'>
                    <div className='w-1/3 border-2 rounded-2xl m-2 flex flex-col'>
                        <div className=' border-b-2 bg-blue-50 rounded-2xl '>
                            <div className='mx-2 px-2 py-4 text-center '>
                                <h1 className='font-bold text-xs'>Steps To Connect Shopify Store</h1>
                            </div>
                        </div>
                        <div className='my-4 py-2 mx-2'>
                            <ol className="border-l">
                                <li>
                                    <div className="flex-start flex items-center">
                                        <div className="-ml-[5px] -mt-2 mr-3 h-[9px] w-[9px] rounded-full bg-gray-400">
                                        </div>
                                        <p className="text-xs -mt-2">
                                            Login to shopify Admin Panel and Go to your Store
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div className="flex-start flex items-center my-4">
                                        <div
                                            className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-gray-400"></div>
                                        <p className="text-xs">
                                            Copy your shop name
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex-start flex items-center my-4">
                                        <div
                                            className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-gray-400"></div>
                                        <p className="text-xs">
                                            Now paste in the shop input box on Couriero's portal
                                        </p>
                                    </div>
                                </li>

                                <li>
                                    <div className="flex-start flex items-center my-4">
                                        <div
                                            className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-gray-400"></div>
                                        <p className="text-xs">
                                            Now Click on Connect Store
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex-start flex items-center my-4">
                                        <div
                                            className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-gray-400"></div>
                                        <p className="text-xs">
                                            Follow the istructions on the shopify partner portal
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex-start flex items-center my-4">
                                        <div
                                            className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-gray-400"></div>
                                        <p className="text-xs">
                                            Follow the istructions on the shopify partner portal
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex-start flex items-center my-4">
                                        <div
                                            className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-gray-400"></div>
                                        <p className="text-xs">
                                            Click on Install when prompted
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex-start flex items-center my-4">
                                        <div
                                            className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-gray-400"></div>
                                        <p className="text-xs">
                                            You will be redirected back to Couriero's Portal
                                        </p>
                                    </div>
                                </li>
                                <li>
                                    <div className="flex-start flex items-center my-4">
                                        <div
                                            className="-ml-[5px] mr-3 h-[9px] w-[9px] rounded-full bg-gray-400"></div>
                                        <p className="text-xs">
                                            Your shopify store would successfully be added
                                        </p>
                                    </div>
                                </li>
                            </ol>
                        </div>

                    </div>
                    <div className='w-2/3 border-2 rounded-2xl m-2 flex flex-col'>
                        <div className='mx-2 px-4 py-2 border-b-2'>
                            <img src={shopifyLogo} alt='shopifyLogo' className='w-30 h-8' />
                        </div>
                        <div className='mx-6 my-2'>
                            <h1 className=' font-bold'>Shopify Store Integration</h1>
                            <p className='text-xs font-medium'>Connect your Shopify store to Couriero.</p>
                        </div>
                        <form onSubmit={shopifyOnSubmit}>
                            <div className='mx-4 my-4 flex flex-col'>
                                <div className='border-2 rounded-2xl flex flex-col my-2 '>
                                    <div className='px-2 py-2'>
                                        <h3 className='font-bold text-sm'>Basic Details</h3>
                                        <p className='text-xs'>Kindly complete the following information to establish a connection for your Shopify store.</p>
                                    </div>
                                    <div className='my-6 flex flex-col'>
                                        <div className='mx-8 my-4 justify-between flex flex-row items-center'>
                                            <label className='text-sm'>Channel Name</label>
                                            <input
                                                type='text'
                                                className='rounded-2xl border-2 px-4 py-1 w-6/12 text-xs '
                                                value={shopifyStoreFields.channelName}
                                                disabled
                                            />
                                        </div>
                                        <div className='mx-8 my-4 justify-between flex flex-row items-center'>
                                            <label className='text-sm'>Brand Name</label>
                                            <input
                                                type='text'
                                                className='rounded-2xl border-2 px-4 py-1 w-6/12 text-xs'
                                                onChange={inputOnChange}
                                                name='brandName'
                                                id='brandName'
                                                value={shopifyStoreFields.brandName}

                                            />
                                        </div>
                                        <div className='mx-8 my-4 justify-between flex flex-row items-center'>
                                            <label className='text-sm'>Shop</label>
                                            <input
                                                type='text'
                                                autoComplete="off"
                                                className='rounded-2xl border-2 px-4 py-1 w-6/12 text-xs '
                                                onChange={inputOnChange}
                                                name='storeUrl'
                                                id='storeUrl'
                                                placeholder='example.myshopify.com'
                                                value={shopifyStoreFields.storeUrl}
                                                required
                                            />
                                        </div>
                                        <div className='my-10 items-center justify-center flex'>
                                            <button
                                                className='bg-blue-500 text-white rounded-2xl px-4 py-1'
                                                type='submit'
                                            >Save Details</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}

export default ShopifyChannel
