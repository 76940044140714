import React from 'react'

const Toast = (props) => {

    const icon = props.toastArgs.type === 'success' ? '✅' : props.toastArgs.type === 'error' ? '❌' : '⚠️'

    return (

        <React.Fragment>

            <div className= "fixed top-15 right-10 px-4 py-2 bg-gray-700 text-white rounded-2xl shadow-2xl transition-opacity opacity-80 pointer-events-auto">
                <span className={`inline-block mr-2 ${props.toastArgs.type === 'success'
                    ? 'text-green-500'
                    : props.toastArgs.type === 'error'
                        ? 'text-red-500'
                        : 'text-yellow-500'
                    }`}>{icon}</span>
                <span>{props.toastArgs.message}</span>
            </div>

        </React.Fragment>
    )
}

export default Toast
