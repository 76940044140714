import walletData from "./wallet/walletDataReducer";
import ordersData from "./orders/ordersReducer";
import trackingData from "./tracking/trackingReducer";
import userDetails from "./userDetails/userDetailsReducer"
import codremittanceData from "./codRemittance/codReducer"
import channelsData from "./channels/channelsReducer"
import reconciliationData from "./reconciliation/reconciliationReducer"
import channelOrdersData from "./channelOrders/channelOrdersReducer";

import { combineReducers } from "redux";

const rootReducer = combineReducers({
    walletData,
    ordersData,
    trackingData,
    userDetails,
    codremittanceData,
    channelsData,
    reconciliationData,
    channelOrdersData
})

export default rootReducer