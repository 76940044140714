import React, { useState } from 'react'
import { Link } from 'react-router-dom'



const SidebarContent = () => {

  const [orderMenu, setOrderMenu] = useState({ ulClass: "hidden", buttonClass: "" })
  const [shipMenu, setShipMenu] = useState({ ulClass: "hidden", buttonClass: "" })
  const [billMenu, setbillMenu] = useState({ ulClass: "hidden", buttonClass: "" })
  const [csMenu, setCsMenu] = useState({ ulClass: "hidden", buttonClass: "" })
  const [ccMenu, setCcMenu] = useState({ ulClass: "hidden", buttonClass: "" })
  const [settingsMenu, setSettingsMenu] = useState({ ulClass: "hidden", buttonClass: "" })



  const orderMenuClick = () => {
    if (orderMenu.ulClass === "hidden") {
      setOrderMenu({ ulClass: "block", buttonClass: "bg-blue-200" })
      setShipMenu({ ulClass: "hidden", buttonClass: "" })
      setbillMenu({ ulClass: "hidden", buttonClass: "" })
      setCsMenu({ ulClass: "hidden", buttonClass: "" })
      setCcMenu({ ulClass: "hidden", buttonClass: "" })
      setSettingsMenu({ ulClass: "hidden", buttonClass: "" })
    } else {
      setOrderMenu({ ulClass: "hidden", buttonClass: "" })
    }
  }

  const shipMenuClick = () => {
    if (shipMenu.ulClass === "hidden") {
      setShipMenu({ ulClass: "block", buttonClass: "bg-blue-200" })
      setOrderMenu({ ulClass: "hidden", buttonClass: "" })
      setbillMenu({ ulClass: "hidden", buttonClass: "" })
      setCsMenu({ ulClass: "hidden", buttonClass: "" })
      setCcMenu({ ulClass: "hidden", buttonClass: "" })
      setSettingsMenu({ ulClass: "hidden", buttonClass: "" })
    } else {
      setShipMenu({ ulClass: "hidden", buttonClass: "" })
    }
  }

  const billMenuClick = () => {
    if (billMenu.ulClass === "hidden") {
      setbillMenu({ ulClass: "block", buttonClass: "bg-blue-200" })
      setOrderMenu({ ulClass: "hidden", buttonClass: "" })
      setShipMenu({ ulClass: "hidden", buttonClass: "" })
      setCsMenu({ ulClass: "hidden", buttonClass: "" })
      setCcMenu({ ulClass: "hidden", buttonClass: "" })
      setSettingsMenu({ ulClass: "hidden", buttonClass: "" })
    } else {
      setbillMenu({ ulClass: "hidden", buttonClass: "" })
    }
  }

  const csMenuClick = () => {
    if (csMenu.ulClass === "hidden") {
      setCsMenu({ ulClass: "block", buttonClass: "bg-blue-200" })
      setOrderMenu({ ulClass: "hidden", buttonClass: "" })
      setShipMenu({ ulClass: "hidden", buttonClass: "" })
      setbillMenu({ ulClass: "hidden", buttonClass: "" })
      setCcMenu({ ulClass: "hidden", buttonClass: "" })
      setSettingsMenu({ ulClass: "hidden", buttonClass: "" })
    } else {
      setCsMenu({ ulClass: "hidden", buttonClass: "" })
    }
  }

  const ccMenuClick = () => {
    if (ccMenu.ulClass === "hidden") {
      setCcMenu({ ulClass: "block", buttonClass: "bg-blue-200" })
      setOrderMenu({ ulClass: "hidden", buttonClass: "" })
      setShipMenu({ ulClass: "hidden", buttonClass: "" })
      setbillMenu({ ulClass: "hidden", buttonClass: "" })
      setCsMenu({ ulClass: "hidden", buttonClass: "" })
      setSettingsMenu({ ulClass: "hidden", buttonClass: "" })
    } else {
      setCcMenu({ ulClass: "hidden", buttonClass: "" })
    }
  }

  const settingsMenuClick = () => {
    if (settingsMenu.ulClass === "hidden") {
      setSettingsMenu({ ulClass: "block", buttonClass: "bg-blue-200" })
      setCcMenu({ ulClass: "hidden", buttonClass: "" })
      setOrderMenu({ ulClass: "hidden", buttonClass: "" })
      setShipMenu({ ulClass: "hidden", buttonClass: "" })
      setbillMenu({ ulClass: "hidden", buttonClass: "" })
      setCsMenu({ ulClass: "hidden", buttonClass: "" })
    } else {
      setSettingsMenu({ ulClass: "hidden", buttonClass: "" })
    }
  }


  return (
    <React.Fragment>


      <div className="w-full mt-10 overflow-x-hidden h-full overflow-y-hidden md:overflow-y-auto flex flex-col">
        <div className='px-3' >
          <h4 className='text-xs uppercase mb-4 font-semibold text-gray-500'>Menu</h4>
          <ul className="space-y-2">
            <li>
              <Link to="/" className="flex items-center py-2 px-1 md:px-2 text-base text-gray-700 rounded-2xl hover:bg-blue-100">
                <i className='fa-solid fa-house-chimney text-xl text-gray-600' />
                <span className="flex-1 md:ml-3 ml-5 text-left text-sm whitespace-nowrap font-medium">Dashboard</span>
              </Link>
            </li>
            <li>
              <button
                type="button"
                className={`flex items-center py-2 px-1 md:px-2 w-full text-base  text-gray-700 rounded-2xl transition duration-75 group hover:bg-blue-100  ${orderMenu.buttonClass}`}
                onClick={orderMenuClick}
              >
                <i className='fa-solid fa-cart-shopping text-xl text-gray-600 ' />
                <span className="flex-1 md:ml-3 ml-5 text-left text-sm whitespace-nowrap font-medium">Orders</span>
                <i className="fa-solid fa-angle-down"></i>
              </button>

              <ul id="dropdown-example" className={`${orderMenu.ulClass} mt-4 space-y-2 md:ml-8 whitespace-nowrap`}>
                <div className='rounded-3xl bg-orange-200 py-3 px-2 shadow-2xl'>
                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 md:pl-2 py-1'>
                      <i className="fa-solid fa-plus w-2/12 pr-2"></i>
                      <Link to="/create-shipment" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate">Create Shipment</Link>
                    </div>
                  </li>

                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2'>
                      <i className="fa-solid fa-rectangle-list w-2/12 pr-2"></i>
                      <Link to="/all-orders" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate">All Shipments</Link>
                    </div>
                  </li>

                  {/* <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2'>
                      <i className="fa-solid fa-download w-2/12 pr-2"></i>
                      <Link to="#" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate">Download Manifest</Link>
                      <i className="fa-solid fa-lock text-red-400 text-xs "></i>
                    </div>
                  </li>

                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2'>
                      <i className="fa-solid fa-box-open w-2/12 pr-2"></i>
                      <Link to="#" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate">Generate Pickup</Link>
                      <i className="fa-solid fa-lock text-red-400 text-xs "></i>
                    </div>
                  </li> */}

                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2 '>
                      <i className="fa-solid fa-microchip w-2/12 pr-2"></i>
                      <Link to="/channel-orders" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate"> Channel Orders</Link>
                    </div>
                  </li>
                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2 '>
                      <i className="fa-solid fa-envelopes-bulk w-2/12 pr-2"></i>
                      <Link to="/bulk-orders" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate"> Bulk Orders Upload</Link>
                    </div>
                  </li>
                </div>
              </ul>
            </li>

            <li>
              <button
                type="button"
                className={`flex items-center py-2 px-1 md:px-2 w-full text-base text-gray-700 rounded-2xl transition duration-75 group hover:bg-blue-100 ${shipMenu.buttonClass}`}
                onClick={shipMenuClick}
              >
                < i className="fa-solid fa-truck-moving text-xl text-gray-600"> </i>
                <span className="flex-1 md:ml-3 ml-5 text-left text-sm whitespace-nowrap font-medium">Shipments</span>
                <i className="fa-solid fa-angle-down"></i>
              </button>
              <ul id="dropdown-example" className={`${shipMenu.ulClass} mt-4 space-y-2 md:ml-8 whitespace-nowrap`}>
                <div className='rounded-3xl bg-orange-200 py-3 px-2 shadow-2xl'>
                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2 '>
                      <i className="fa-solid fa-arrow-rotate-left w-2/12 pr-2"></i>
                      <Link to="#" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate">RTO Shipments</Link>
                      <i className="fa-solid fa-lock text-red-400 text-xs "></i>
                    </div>
                  </li>

                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2 '>
                      <i className="fa-solid fa-triangle-exclamation w-2/12 pr-2"></i>
                      <Link to="#" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate">Process NDR</Link>
                      <i className="fa-solid fa-lock text-red-400 text-xs "></i>
                    </div>
                  </li>
                </div>
              </ul>
            </li>

            <li>
              <button
                type="button"
                className={`flex items-center py-2 px-1 md:px-2 w-full text-base text-gray-700 rounded-2xl transition duration-75 group hover:bg-blue-100 ${billMenu.buttonClass}`}
                onClick={billMenuClick}
              >
                <i className='fa-solid fa-cart-shopping text-xl text-gray-600' />
                <span className="flex-1 md:ml-3 ml-5 text-left text-sm whitespace-nowrap font-medium">Billing</span>
                <i className="fa-solid fa-angle-down"></i>
              </button>

              <ul id="dropdown-example" className={`${billMenu.ulClass} mt-4 space-y-2 md:ml-8 whitespace-nowrap`}>
                <div className='rounded-3xl bg-orange-200 py-3 px-2 shadow-2xl'>
                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2 '>
                      <i className="fa-solid fa-calculator w-2/12 pr-2"></i>
                      <Link to="/rate-calculator" className="md:ml-1 ml-2 w-10/12 text-xs font-medium overflow-hidden truncate md:whitespace-normal">Shipping Charge Calculator</Link>
                    </div>
                  </li>

                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2 '>
                      <i className="fa-solid fa-wallet w-2/12 pr-2"></i>
                      <Link to="/wallet" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate">Wallet History</Link>
                    </div>
                  </li>

                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2 '>
                      <i className="fa-solid fa-hand-holding-dollar w-2/12 pr-2"></i>
                      <Link to="/cod-remittance" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate">COD Remittance</Link>
                    </div>
                  </li>

                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2 '>
                      <i className="fa-solid fa-file-invoice w-2/12 pr-2"></i>
                      <Link to="#" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate">Invoices</Link>
                      <i className="fa-solid fa-lock text-red-400 text-xs "></i>
                    </div>
                  </li>

                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2 '>
                      <i className="fa-solid fa-scale-balanced w-2/12 pr-2"></i>
                      <Link to="/weight-reconciliation" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate md:whitespace-normal">Weight Reconciliation</Link>
                    </div>
                  </li>
                </div>
              </ul>
            </li>

            <li>
              <button
                type="button"
                className={`flex items-center py-2 px-1 md:px-2 w-full text-base text-gray-700 rounded-2xl transition duration-75 group hover:bg-blue-100 ${csMenu.buttonClass}`}
                onClick={csMenuClick}
              >
                <i className="fa-solid fa-headphones-simple text-xl text-gray-600 "></i>
                <span className="flex-1 md:ml-3 ml-5 text-left text-sm whitespace-wrap font-medium">Customer Support</span>
                <i className="fa-solid fa-angle-down"></i>
              </button>

              <ul id="dropdown-example" className={`${csMenu.ulClass} mt-4 space-y-2 md:ml-8 whitespace-nowrap`}>
                <div className='rounded-3xl bg-orange-200 py-3 px-2 shadow-2xl'>
                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2 '>
                      <i className="fa-solid fa-ticket w-2/12 pr-2"></i>
                      <Link to="#" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate">Raise & View Tickets</Link>
                      <i className="fa-solid fa-lock text-red-400 text-xs "></i>
                    </div>
                  </li>

                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2 '>
                      <i className="fa-solid fa-circle-question w-2/12 pr-2"></i>
                      <Link to="#" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate">FAQ's</Link>
                      <i className="fa-solid fa-lock text-red-400 text-xs "></i>
                    </div>
                  </li>

                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2 '>
                      <i className="fa-solid fa-file-pen w-2/12 pr-2"></i>
                      <Link to="#" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate">Training</Link>
                      <i className="fa-solid fa-lock text-red-400 text-xs "></i>
                    </div>
                  </li>

                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2 '>
                      <i className="fa-solid fa-scale-balanced w-2/12 pr-2"></i>
                      <Link to="#" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate md:whitespace-normal">Weight Discrepancy Dispute</Link>
                      <i className="fa-solid fa-lock text-red-400 text-xs "></i>
                    </div>
                  </li>
                </div>
              </ul>
            </li>

            <li>
              <button
                type="button"
                className={`flex items-center py-2 px-1 md:px-2 w-full text-base text-gray-700 rounded-2xl transition duration-75 group hover:bg-blue-100 ${ccMenu.buttonClass}`}
                onClick={ccMenuClick}
              >
                <i className="fa-solid fa-address-book text-xl text-gray-600 "></i>
                <span className="flex-1 md:ml-3 ml-5 text-left text-sm whitespace-wrap font-medium">Customer Contacts</span>
                <i className="fa-solid fa-angle-down"></i>
              </button>

              <ul id="dropdown-example" className={`${ccMenu.ulClass} mt-4 space-y-2 md:ml-8 whitespace-nowrap`}>
                <div className='rounded-3xl bg-orange-200 py-3 px-2 shadow-2xl'>
                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2 '>
                      <i className="fa-solid fa-file-circle-plus w-2/12 pr-2"></i>
                      <Link to="#" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate">Add Customers</Link>
                      <i className="fa-solid fa-lock text-red-400 text-xs "></i>
                    </div>
                  </li>

                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2 '>
                      <i className="fa-solid fa-list w-2/12 pr-2"></i>
                      <Link to="#" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate">View All Customers</Link>
                      <i className="fa-solid fa-lock text-red-400 text-xs "></i>
                    </div>
                  </li>
                </div>
              </ul>
            </li>

            <li>
              <button
                type="button"
                className={`flex items-center py-2 px-1 md:px-2 w-full text-base text-gray-700 rounded-2xl transition duration-75 group hover:bg-blue-100 ${settingsMenu.buttonClass}`}
                onClick={settingsMenuClick}
              >
                <i className="fa-solid fa-gear text-xl text-gray-600 "></i>
                <span className="flex-1 md:ml-3 ml-5 text-left text-sm whitespace-nowrap font-medium">Settings</span>
                <i className="fa-solid fa-angle-down"></i>
              </button>

              <ul id="dropdown-example" className={`${settingsMenu.ulClass} mt-4 space-y-2 md:ml-8 whitespace-wrap`}>
                <div className='rounded-3xl bg-orange-200 py-3 px-2 shadow-2xl'>
                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2 '>
                      <i className="fa-solid fa-user w-2/12 pr-2"></i>
                      <Link to="/profile" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate">Profile</Link>
                    </div>
                  </li>

                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2 '>
                      <i className="fa-solid fa-location-dot w-2/12 pr-2"></i>
                      <Link to="#" className="md:ml-1 ml-2 w-10/12 text-xs font-medium overflow-hidden truncate md:whitespace-normal ">Manage Pickup Locations</Link>
                      <i className="fa-solid fa-lock text-red-400 text-xs "></i>
                    </div>
                  </li>

                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2 '>
                      <i className="fa-sharp fa-solid fa-id-card w-2/12 pr-2"></i>
                      <Link to="/kyc" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate">KYC</Link>
                    </div>
                  </li>
                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2 '>
                      <i className="fa-solid fa-building-columns w-2/12 pr-2"></i>
                      <Link to="#" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate">Bank Details</Link>
                      <i className="fa-solid fa-lock text-red-400 text-xs "></i>
                    </div>
                  </li>
                  <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2 '>
                      <i className="fa-solid fa-file-invoice w-2/12 pr-2"></i>
                      <Link to="#" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate">Invoice Settings</Link>
                      <i className="fa-solid fa-lock text-red-400 text-xs "></i>
                    </div>
                  </li>
                   <li>
                    <div className='flex flex-row items-center  text-gray-600 rounded-2xl hover:bg-blue-100 py-1 md:pl-2 '>
                      <i className="fa-solid fa-shop w-2/12 pr-2 "></i>
                      <Link to="/channels" className="md:ml-1 ml-2 w-10/12 text-xs font-medium truncate">Channel Integration</Link>
                    </div>
                  </li>
                </div>
              </ul>
            </li>


          </ul>

        </div>
        <div className='flex flex-row items-center my-8 px-4 text-gray-500'>
          <i className="fa-solid fa-lock text-red-400 text-xs "> </i>
          <p className='mx-2 text-xs font-bold uppercase'>- Coming Soon</p>
        </div>
      </div >

    </React.Fragment >
  )
}

export default SidebarContent
