import React, { useState } from 'react'
import logo from "../../Assets/logo.png"
import AuthCode from 'react-auth-code-input';
import { Link, useNavigate } from 'react-router-dom';
import Toast from '../../components/toast/Toast';



const ForgotPassword = () => {

    let navigate = useNavigate();


    const [forgotPasswordDetails, setForgotPasswordDetails] = useState({ email: "" })
    const [newPasswordDetails, setNewPasswordDetails] = useState({ newPassword: "", confirmPassword: "" })

    const [emailSection, setEmailSection] = useState("block")
    const [otpSection, setOtpSection] = useState("hidden")
    const [newPasswordSection, setNewPasswordSection] = useState("hidden")
    const [otpResult, setOtpResult] = useState("")
    const [sessionId, setSessionId] = useState("")

    // const [toastArgs, setToastArgs] = useState({message: "", type: ""})
    const [toastArgs, setToastArgs] = useState(null)


    const showToast = (message, type) => {
        setToastArgs({
            message,
            type
        })
        setTimeout(() => {
            setToastArgs(null)
        }, 3000);
    }

    const handleOtpSection = (e) => {
        e.preventDefault();
        checkEmailId(forgotPasswordDetails.email)
    }

    const checkEmailId = async (emailId) => {
        const response = await fetch('https://api.couriero.in/api/auth/get-user-by-email', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                emailId: emailId
            })
        })

        const result = await response.json()
        if (result.error === 'Use correct credentials') {
            showToast('Invalid Email Id', 'error')
        } else {
            sendOtp(result.mobNo);
            setEmailSection("hidden");
            setOtpSection("block")
        }
        console.log('search by email is', result)
    }

    const sendOtp = async (mobileNumber) => {
        const response = await fetch('https://api.couriero.in/sms/smsOtp', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                mobileNumber: mobileNumber
            })
        });
        const result = await response.json()
        if (result.Status === "Success") {
            showToast("OTP sent successfully", "success")
        } else {
            showToast("OTP Not sent", "error")
        }
        setSessionId(result.Details)
    }

    const forgotPasswordOnChange = (e) => {
        setForgotPasswordDetails({ ...forgotPasswordDetails, [e.target.name]: e.target.value })
    }

    const handleOtpChange = (res) => {
        setOtpResult(res)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        verifyOtp(sessionId, otpResult)
    }

    const verifyOtp = async (sessionId, otpResult) => {
        const response = await fetch(`https://api.couriero.in/sms/verifyOtp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                sessionId: sessionId,
                otp: otpResult
            })
        })
        const result = await response.json();
        console.log('the result is', result)
        if (result.Status === 'Success') {
            setNewPasswordSection('block')
            setOtpSection("hidden")

        }
    }

    const handleNewPasswordSection = async (e) => {
        e.preventDefault()
        if (newPasswordDetails.newPassword === newPasswordDetails.confirmPassword) {
            const response = await fetch('https://api.couriero.in/api/auth/forgot-password', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    emailId: forgotPasswordDetails.email,
                    newPassword: newPasswordDetails.newPassword
                })

            })

            const result = await response.json()
            
            if (result.status === 'Success') {
                navigate('/login')
                showToast("Password Changed Successfully", "success") 
            }
        } else {
            showToast('Password and confirm password do not match', 'error')
        }

    }

    const newPasswordOnChange = (e) => {
        setNewPasswordDetails({ ...newPasswordDetails, [e.target.name]: e.target.value })
    }
    return (
        <React.Fragment>
            <div className='w-full h-full fixed items-center bg-gray-50 flex flex-col align-middle overflow-y-scroll text-slate-600' >
                <img src={logo} className=' w-60 h-10 my-10 ' alt='logo' />
                <div className='bg-white lg:w-[30rem] lg:h-[30rem] w-10/12 py-6  rounded-2xl shadow-2xl align-middle'>
                    {toastArgs !== null && <Toast toastArgs={toastArgs}/>} 

                    <form onSubmit={handleOtpSection}>
                        <div className={`${emailSection} flex flex-col`}>
                            <Link to='/login'>

                                <div className='flex flex-row items-center my-4 mx-2 hover:cursor-pointer hover:text-blue-800 text-blue-500'>
                                    <i className="mx-4 fa-solid fa-arrow-left-long"></i>
                                    <h3 className='text-sm hover:underline'>Back</h3>
                                </div>
                            </Link>

                            <div className='my-4 mx-4 text-center text-gray-500 font-bold text-lg'>
                                <h1>Forgot Password</h1>
                            </div>
                            <div className='mx-6 mt-2'>

                                <div className='my-8'>
                                    <label htmlFor='email' className="text-sm font-medium leading-none text-gray-500">Enter your email Id to receive OTP on your registered mobile number for password reset.</label>
                                    <input name='email' onChange={forgotPasswordOnChange} value={forgotPasswordDetails.email} id='email' placeholder="Enter email adress" type="email" className="border rounded-2xl text-xs font-medium leading-none text-gray-600 py-2 w-full pl-3 mt-8" required />
                                </div>

                            </div>
                            <div className="mt-4 mx-20">
                                <button type="submit" className=" text-sm font-semibold leading-none text-white border rounded-2xl hover:bg-blue-600 bg-blue-500 py-2 w-full">
                                    Send OTP
                                </button>
                            </div>
                        </div>
                    </form>

                    <form onSubmit={handleSubmit}>
                        <div className={`${otpSection} flex flex-col lg:mt-28 mt-4`}>
                            <div className='lg:mx-6 mt-2 text-center mx-2 flex flex-col items-center'>
                                <h4 className='mb-4 '>Enter OTP</h4>
                                <AuthCode
                                    onChange={handleOtpChange}
                                    inputClassName={'border border-solid mx-1 py-1 w-10 border-gray-300 text-center rounded-2xl'}
                                    allowedCharacters={'numeric'}
                                />
                            </div>
                            <div className="mt-8 mx-6">
                                <button type='submit' className=" text-sm font-semibold leading-none text-white border rounded-2xl hover:bg-blue-600 bg-blue-500  py-2 w-full">
                                    Verify OTP
                                </button>
                            </div>
                        </div>
                    </form>

                    <form onSubmit={handleNewPasswordSection}>
                        <div className={`${newPasswordSection} text-slate-600 lg:mt-16 mt-4`}>

                            <div className='my-4 mx-4 text-center text-gray-500 font-bold text-lg'>
                                <h1>New Password</h1>
                            </div>

                            <div className='mx-6 my-6 flex flex-col'>

                                <div className='my-4 flex flex-col'>
                                    <label htmlFor='newPassword' className="text-xs font-medium leading-none text-gray-800">Enter New Password</label>
                                    <input name='newPassword' onChange={newPasswordOnChange} value={newPasswordDetails.newPassword} id='newPassword' placeholder="Enter New Password" type="password" className="border rounded-2xl text-xs font-medium leading-none text-gray-600 py-2 w-full pl-3 mt-2" required />
                                </div>

                                <div className='my-4 flex flex-col'>
                                    <label htmlFor='confirmPassword' className="text-xs font-medium leading-none text-gray-800">Confirm New Password</label>
                                    <input name='confirmPassword' onChange={newPasswordOnChange} value={newPasswordDetails.confirmPassword} id='confirmPassword' placeholder="Confirm New Password" type="password" className="border rounded-2xl text-xs font-medium leading-none text-gray-600 py-2 w-full pl-3 mt-2" required />
                                </div>

                            </div>
                            <div className="mt-4 mx-20">
                                <button type="submit" className=" text-sm font-semibold leading-none text-white border rounded-2xl hover:bg-blue-600 bg-blue-500 py-2 w-full">
                                    Change Password
                                </button>
                            </div>
                        </div>
                    </form>


                </div>
            </div>
        </React.Fragment>
    )
}

export default ForgotPassword
