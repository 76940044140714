import React from 'react'

const Breadcrumb = props => {
  return (
    <div className='w-full flex-row flex justify-between pb-4'>
      <div className='align-middle '>
        <h4 className='uppercase font-black text-base text-gray-600 align-middle'>{props.breadcrumbItem}</h4>
      </div>
      <div className='  align-middle '>
        <h4 className='text-gray-600 font-normal'>{`${props.title} / ${props.breadcrumbItem}`}</h4>
      </div>

    </div>
  )
}

export default Breadcrumb
