import React from 'react'

const Progressbar = (props) => {

    return (
        <>
            <div className="progress" data-percentage={props.grading} >
                <span className="progress-left">
                    <span className="progress-bar"></span>
                </span>
                <span className="progress-right">
                    <span className="progress-bar"></span>
                </span>
                <div className="progress-value">{props.progressValue}</div>
            </div>
            <span className="text-black text-xs font-medium">
               <h5 className='pt-3'>{props.progressBarName}</h5>
            </span>
        </>
    )
}

export default Progressbar
