import { SET_TRACKING_DATA } from "./trackingActionType"

const trackingDataState = []

const trackingData = (state = trackingDataState, action) => {
    switch (action.type) {
        case SET_TRACKING_DATA:
            return [...action.data.tracking]

        default: return state
    }
}

export default trackingData