import React from 'react'

const TodayCard = (props) => {
    return (
        <React.Fragment>
            <div className='flex flex-row p-4 justify-between align-middle'>
                <div className='mt-2'>
                    <p className='text-sm font-medium'>{props.title}</p>
                    <h5 className='text-xl font-semibold'>{props.amount}</h5>
                </div>
                <div className='rounded-full bg-blue-200 w-16 h-16 text-center'>
                    <i className={`fa-solid fa-${props.lable} text-blue-600 text-3xl pt-3`}></i>
                </div>
            </div>
        </React.Fragment>
    )
}

export default TodayCard
