import { ADD_CREDIT_WALLET_TRANSACTION, ADD_WALLET_TRANSACTION, GET_ALL_WALLET_TRANSACTION } from "./actionTypes"


export const addWalletTransaction = (transactionData) => {
    return {
        type: ADD_WALLET_TRANSACTION,
        payload: transactionData
    }
}

export const addCreditWalletTransaction = (transactionData) => {
    return {
        type: ADD_CREDIT_WALLET_TRANSACTION,
        payload: transactionData
    }
}

export const getAllWalletTransaction = () => {
    return {
        type: GET_ALL_WALLET_TRANSACTION,
    }
}