import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const ShipmentTypeAnalytics = props => {

    const allOrdersData = useSelector((state) => state.ordersData.allOrders)
    const [allOrders, setAllOrders] = useState([])
    const series = []

    useEffect(() => {
        setAllOrders(allOrdersData)
    }, [allOrdersData])

    const prepaidShipments = allOrders.reduce((accumulator, obj) => {
        if (obj.productOrderType === 'Pre-paid' || obj.productOrderType === 'prepaid') {
          return accumulator + 1;
        }
        return accumulator;
      }, 0);

    const codShipments = allOrders.length - prepaidShipments

    series.push(codShipments)
    series.push(prepaidShipments)


    //const series = [30, 200];
    const options = {
        labels: ["COD", "Pre Paid"],
        colors: ["#556ee6", "#f1b44c"],
        legend: { show: !1 },
        plotOptions: {
            pie: {
                donut: {
                    size: "70%",
                },
            },
        },
    };


    return (
        <React.Fragment>
            <div className="w-full p-5">
                <div className="flex flex-col">
                    <div className="text-left">
                        <h3 className="text-lg font-semibold">Shipment Type Analytics</h3>
                    </div>
                    <div className="my-2">
                        <ReactApexChart
                            options={options}
                            series={series}
                            type="donut"
                            height={230}
                            className="apex-charts"
                        />
                    </div>
                    <div className="my-2">
                        <div className="flex flex-row justify-evenly pb-3">
                            <div className="align-middle text-center">
                                <p className="text-sm font-medium">
                                    <i className="fa-solid fa-circle mr-2" style={{color: '#f1b44c'}}></i>
                                    Pre Paid
                                </p>
                                <h5 className="text-sm font-semibold mt-2">{prepaidShipments}</h5>
                            </div>
                            <div className="align-middle text-center">
                                <p className="text-sm font-medium">
                                    <i className="fa-solid fa-circle mr-2" style={{color: '#556ee6'}}></i>
                                    COD
                                </p>
                                <h5 className="text-sm font-semibold mt-2">{codShipments}</h5>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ShipmentTypeAnalytics;
