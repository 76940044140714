import { all, fork, put, takeEvery } from "redux-saga/effects"
import { GET_COD_REMITTANCE, SET_COD_REMITTANCE } from "./codActionTypes"

function* getCodRemittance() {
    const response = yield fetch('https://api.couriero.in/cod-remittance/get-remittance', {
        method: 'POST',
        headers: {
            'auth-token': localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    })

    const result = yield response.json()
    yield put({type: SET_COD_REMITTANCE, data: result})

}

function* watchGetCodRemittance() {
    yield takeEvery(GET_COD_REMITTANCE, getCodRemittance )
}

function* codRemittanceSaga() {
    yield all([
        fork(watchGetCodRemittance)
    ])
}

export default codRemittanceSaga