import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Breadcrumb from '../../components/common/Breadcrumb'
import { getTrackingData } from '../../Store/tracking/trackingActions'

const OrderDetails = () => {
    const dispatch = useDispatch()
    const [selectedOrderData, setSelectedOrderData] = useState({ orderDate: '' })
    const [trackingdetails, setTrackingdetails] = useState([])

    const params = useParams()
    const paramsAwb = params.awb

    const allOrdersData = useSelector((state) => state.ordersData.allOrders)
    const trackingData = useSelector((state) => state.trackingData)






    const selectedOrder = allOrdersData.find(obj => {
        return obj.awbNo === paramsAwb
    })

    useEffect(() => {
        console.log('param saawb is ', paramsAwb)
        dispatch(getTrackingData(paramsAwb))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (trackingData.length !== 0) {
            console.log('trackind dtata is', trackingData)
            setTrackingdetails(trackingData[0].scanData)
        }
    }, [trackingData])





    useEffect(() => {
        if (typeof selectedOrder !== "undefined") {
            setSelectedOrderData(selectedOrder)
            console.log('the order selected is', selectedOrder)
            dispatch(getTrackingData(selectedOrder.awbNo))
        }
        // eslint-disable-next-line
    }, [selectedOrder])


    const getDateTime = (date) => {
        const dateFormat = new Date(date)
        const day = dateFormat.getDate()
        const month = dateFormat.getMonth() + 1
        const year = dateFormat.getFullYear()
        const time = dateFormat.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true, timeZone: 'Asia/Kolkata' })
        return `${day}/${month}/${year}, ${time} `


    }

    const capitalizeFirstWord = (input) => {
        if (typeof input !== 'undefined') {
            var word = input.trim().replace(/[\s\t]+/g, ' ');
            return word.split(' ').map(word => word[0].toUpperCase() + word.slice(1)).join(' ');
        };
    }





    return (
        <React.Fragment>
            <div className='flex flex-col text-gray-600 mb-12 w-full '>
                <div>
                    <Breadcrumb breadcrumbItem="Order Details" title="All Orders" />
                </div>
                <div className='flex md:flex-row flex-col'>

                    <div className='md:w-7/12 mx-2 w-full flex flex-col overflow-y-auto md:max-h-screen' >
                        <div className='bg-white rounded-2xl  my-2 flex flex-col py-2 border border-gray-200'>
                            <div className='flex flex-row items-center border-b-2 mx-2 py-2'>
                                <div className="ml-3 w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center text-white relatives z-10">
                                    <i className="fa-solid fa-cart-arrow-down"></i>
                                </div>
                                <div>
                                    <h2 className='text-base font-semibold ml-2'>Order Details</h2>
                                </div>
                            </div>
                            <div className='md:flex md:flex-row grid grid-rows-2 grid-flow-col gap-2 md:items-center w-full mx-4 my-2 md:px-4'>
                                <div className='md:w-1/3 flex flex-col' >
                                    <h3 className='text-xs font-medium'>Awb No.</h3>
                                    <h3 className='text-xs font-light'>{selectedOrderData.awbNo}</h3>
                                </div>
                                <div className='md:w-1/3 flex flex-col' >
                                    <h3 className='text-xs font-medium'>Order Id</h3>
                                    <h3 className='text-xs font-light'>{selectedOrderData.orderId}</h3>
                                </div>
                                <div className='md:w-1/3 flex flex-col' >
                                    <h3 className='text-xs font-medium'>Order Date</h3>
                                    <h3 className='text-xs font-light'>{selectedOrderData.orderDate.split("T")[0]}</h3>
                                </div>
                            </div>
                        </div>

                        <div className='bg-white rounded-2xl  my-2 flex flex-col py-2 border border-gray-200'>
                            <div className='flex flex-row items-center border-b-2 mx-2 py-2'>
                                <div className="ml-3 w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center text-white relatives z-10">
                                    <i className="fa-solid fa-box-open"></i>
                                </div>
                                <div>
                                    <h2 className='text-base font-semibold ml-2'>Product Details</h2>
                                </div>
                            </div>
                            <div className='mt-2 flex flex-col mx-2'>
                                <div className='flex flex-row rounded-2xl  bg-gray-200 px-3 py-1'>
                                    <h3 className='md:w-2/12 w-1/4 text-sm font-medium text-left'>Name</h3>
                                    <h3 className='md:w-2/12 w-1/4 text-sm font-medium text-center'>Quantity</h3>
                                    <h3 className='md:w-2/12 w-1/4 text-sm font-medium text-center'>Unit Price</h3>
                                    <h3 className='md:w-6/12 w-1/4 text-sm font-medium text-right'>Amount</h3>
                                </div>
                                <div className='flex flex-row px-3 mt-2   '>
                                    <h3 className='md:w-2/12 w-1/4 text-xs text-left'>{selectedOrderData.productName}</h3>
                                    <h3 className='md:w-2/12 w-1/4 text-xs text-center'>1</h3>
                                    <h3 className='md:w-2/12 w-1/4 text-xs text-center'>₹ {selectedOrderData.productAmount}</h3>
                                    <div className='md:w-6/12 w-1/4 text-sm text-right'>
                                        <h3 className=' text-xs pb-2'>₹ {selectedOrderData.productAmount}</h3>
                                    </div>
                                </div>
                                <div className='border-b-2 flex flex-row justify-between items-center ml-2'>
                                    <div className='flex flex-row items-center justify-start'>
                                        <h3 className='md:mr-4 mr-1 text-xs font-semibold'>Payment Mode: </h3>
                                        <h3 className='text-xs border rounded-2xl bg-green-200 md:px-3 px-1 py-1 text-center'>{selectedOrderData.productOrderType}</h3>
                                    </div>
                                    <div className='text-right'>
                                        <h3 className='border-t-2 py-2 font-semibold mr-2 md:text-base text-xs'>Order total<span className='pl-4'> ₹ {selectedOrderData.productAmount}</span> </h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='bg-white rounded-2xl  my-2 flex flex-col py-2 border border-gray-200'>
                            <div className='flex flex-row items-center border-b-2 mx-2 py-2'>
                                <div className="ml-3 w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center text-white relatives z-10">
                                    <i className="fa-solid fa-truck"></i>
                                </div>
                                <div>
                                    <h2 className='text-base font-semibold ml-2'>Pickup & Delivery Details</h2>
                                </div>
                            </div>
                            <div className='flex md:flex-row flex-col mx-2 '>
                                <div className='py-4 md:w-1/2 w-full md:border-r-2 px-2'>
                                    <div className='border-b-2 mb-4 mr-10 flex flex-row items-center'>
                                        <i className="fa-light fa-person-carry-box"></i>
                                        <h2 className='text-base font-semibold ml-2'>Pickup Address</h2>
                                    </div>
                                    <div className='flex flex-row justify-items-start h-6 items-center'>
                                        <h3 className='text-sm font-medium w-1/2 '> Contact Person</h3>
                                        <h3 className='text-xs font-light w-1/2 '>{selectedOrderData.pickupAddressContactName}</h3>
                                    </div>
                                    <div className='flex flex-row justify-items-start h-6 items-center'>
                                        <h3 className='text-sm font-medium w-1/2 '>Contact Details</h3>
                                        <h3 className='text-xs font-light w-1/2 '>{selectedOrderData.pickupAddressMobNo}</h3>
                                    </div>
                                    <div className='flex flex-row justify-items-start h-6 items-center'>
                                        <h3 className='text-sm font-medium w-1/2 pr-4 '>Address name</h3>
                                        <h3 className='text-xs font-light w-1/2 '>{selectedOrderData.pickupAddressName}</h3>
                                    </div>
                                    <div className='flex flex-row justify-items-start'>
                                        <h3 className='text-sm font-medium w-1/2 '>Address</h3>
                                        <h3 className='text-xs font-light w-1/2 pt-1 '>{selectedOrderData.pickupAddress}, {selectedOrderData.pickupAddressCity}, {selectedOrderData.pickupAddressState}, {selectedOrderData.pickupPinCode} </h3>
                                    </div>
                                </div>
                                <div className='py-4 md:w-1/2 w-full pl-4'>
                                    <div className='border-b-2 mb-4 mr-10'>
                                        <h2 className='text-base font-semibold'>Delivery Address</h2>
                                    </div>
                                    <div className='flex flex-row justify-items-start h-6 items-center'>
                                        <h3 className='text-sm font-medium w-1/2 '> Contact Person</h3>
                                        <h3 className='text-xs font-light w-1/2 '>{selectedOrderData.consigneeName}</h3>
                                    </div>
                                    <div className='flex flex-row justify-start h-6 items-center'>
                                        <h3 className='text-sm font-medium w-1/2 '>Contact Details</h3>
                                        <h3 className='text-xs font-light w-1/2 '>{selectedOrderData.consigneeMobNo}</h3>
                                    </div>
                                    <div className='flex flex-row justify-around '>
                                        <h3 className='text-sm font-medium w-1/2 '>Address</h3>
                                        <h3 className='text-xs font-light w-1/2 pt-1'>{selectedOrderData.consigneeAddress}, {selectedOrderData.consigneeCity}, {selectedOrderData.consigneeState}, {selectedOrderData.consigneePinCode} </h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='bg-white rounded-2xl  my-2 flex flex-col py-2 border border-gray-200'>
                            <div className='flex flex-row items-center border-b-2 mx-2 py-2'>
                                <div className="ml-3 w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center text-white relatives z-10">
                                    <i className="fa-solid fa-scale-unbalanced"></i>
                                </div>
                                <div>
                                    <h2 className='text-base font-semibold ml-2'>Weight & Dimension</h2>
                                </div>
                            </div>
                            <div className='py-4 grid grid-cols-2 gap-3 mx-2'>
                                <h3 className='text-sm font-medium'>Input Weight (in Kg) <br /><span className='text-xs font-light'>{selectedOrderData.shipmentWeight}</span> </h3>
                                <h3 className='text-sm font-medium'>Dimensions (in cm)<br /> <span className='text-xs font-light'>{selectedOrderData.shipmentLength} X {selectedOrderData.shipmentBredth} X {selectedOrderData.shipmentHeight}</span> </h3>
                                <h3 className='text-sm font-medium'>Volumetric Weight (in Kg) <br /><span className='text-xs font-light'>{selectedOrderData.shipmentVolWeight}</span> </h3>
                                <h3 className='text-sm font-medium'>Charged Weight (in Kg) <br /><span className='text-xs font-light'>{selectedOrderData.shipmentChargedWeight}</span> </h3>
                            </div>
                        </div>
                    </div>

                    <div className='md:w-5/12 w-full mx-2 pb-10 md:max-h-screen overflow-y-auto'>
                        <div className='bg-white rounded-2xl  my-2 flex flex-col py-2 border border-gray-200 h-full overflow-y-hidden max-h-fit'>
                            <div className='flex flex-row items-center border-b-2 mx-2 py-2'>
                                <div className="ml-3 w-8 h-8 rounded-full bg-blue-500 flex items-center justify-center text-white relatives z-10">
                                    <i className="fa-solid fa-list-ul"></i>
                                </div>
                                <div>
                                    <h3 className='text-base font-semibold ml-2'> Tracking Details</h3>
                                </div>
                            </div>
                            <div className=" px-2 flex flex-col py-4 h-full overflow-y-auto">
                                {trackingdetails.reverse().map((element) => {
                                    return (
                                        <div className="flex py-3 flex-row" key={`${element.scanDate}-${element.scanTypeCode}`}>
                                            <div className='relative hidden md:block'>
                                                <div className=" h-12 w-10 absolute flex items-center justify-center">
                                                    <div className="h-full w-1 bg-gray-200"></div>
                                                </div>
                                                <div className="ml-3 w-5 h-4 rounded-full bg-blue-500 flex items-center justify-center text-white absolute z-10">
                                                    <i className="fa-solid fa-arrow-right text-xs"></i>
                                                </div>
                                            </div>
                                            <div className="flex md:flex-row  md:ml-10 justify-items-start w-full">
                                                <div className='flex flex-col w-3/12'>
                                                    <h2 className='text-xs font-semibold'>{element.scanType}</h2>
                                                    <h2 className='text-xs font-medium tracking-tighter	'>{getDateTime(element.scanDate)}</h2>
                                                </div>
                                                <h2 className='text-xs font-medium w-4/12 mx-2 tracking-tighter flex-wrap'>{element.scanLocation}</h2>
                                                <h2 className='text-xs font-medium w-5/12 mx-2 tracking-tighter flex-wrap'>{capitalizeFirstWord(element.scanDetail)}</h2>
                                            </div>
                                        </div>
                                    )
                                })
                                }
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </React.Fragment>
    )
}

export default OrderDetails
