import { all, fork, put, takeEvery } from "redux-saga/effects"
import { GET_ALL_CHANNELS, SET_ALL_CHANNELS } from "./channelsActionTypes"


function* getAllChannels() {
    const response = yield fetch('https://api.couriero.in/channels/get-all-channels', {
        method: 'POST',
        headers: {
            'auth-token': localStorage.getItem('token'),
            'Content-Type': 'application/json'
        }
    })

    const result = yield response.json()
    yield put({type: SET_ALL_CHANNELS, data: result})
}

function* watchGetAllChannels() {
    yield takeEvery(GET_ALL_CHANNELS, getAllChannels)
}

function* channelsSaga() {
    yield all([
        fork(watchGetAllChannels)
    ])
}

export default channelsSaga