import { all, fork, put, takeEvery } from "redux-saga/effects";
import { GET_TRACKING_DATA, SET_TRACKING_DATA } from "./trackingActionType";

function* getTrackingData(data){

    const response = yield fetch('https://api.couriero.in/tracking/get-tracking-details', {
        method: 'POST',
        headers: {
            'auth-token': localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            awb: data.payload
        })
    })
    const result = yield response.json()
    yield put({type: SET_TRACKING_DATA, data: result})
}

function* watchGetTrackingData() {
    yield takeEvery(GET_TRACKING_DATA, getTrackingData)
}

function* trackingSaga(){
    yield all([
        fork(watchGetTrackingData)
    ])
}

export default trackingSaga