import React from 'react'
import surface from "../../Assets/surface.svg";

import Progressbar from '../../components/progressbar/Progressbar';


const CreateShipmentPage2 = (props) => {

    return (
        <React.Fragment>
            <div className='hidden lg:block border border-solid border-blue-800 rounded-2xl my-6 shadow-2xl'>
                <div className='p-5 flex flex-row'>
                    <div className='items-center flex flex-row'>
                        <div className='w-1/4 mr-6'>
                            <div className='items-center w-full flex flex-row'>
                                <div className='flex  w-1/2 flex-row'>
                                    <label className='flex flex-row  items-center'>
                                        <input
                                            type="radio"
                                            id="selectedCourierId"
                                            name="selectedCourierId"
                                            className="w-4 h-4 my-2"
                                            value={props.id}
                                            required
                                            onChange={props.selectedCourierIdOnChange}
                                        />
                                        <img
                                            className="rounded cursor-pointer align-middle block mx-2 my-2"
                                            alt={props.courierName}
                                            width="100"
                                            height="20"
                                            src={props.logo}
                                        />
                                    </label>
                                </div>
                                <div className='w-1/2 flex flex-col'>
                                    <div className='flex flex-row'>
                                        <div className='relative w-4/5'>
                                            <h2 className='md:text-2xl text-base font-medium text-gray-700'>{props.courierName}</h2>
                                        </div>
                                        <div className='w-1/5 '>
                                            <img
                                                className="rounded mt-4 ml-4"
                                                alt="Surface"
                                                width="30"
                                                src={surface}
                                            />
                                        </div>
                                    </div>
                                    <div className='text-xs mt-2 bg-green-200 rounded-2xl px-4 text-gray-600 w-full'>
                                        <h4>Min. Weight : <strong>{props.minWeight} Kg</strong></h4>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-1/2 flex border-x-2 mx-4 h-full px-4'>
                            <div className='w-full flex flex-col px-2 h-3/5'>
                                <div className=' flex flex-row  content-evenly'>
                                    <div className='w-1/5 pl-2 text-center'>
                                        <Progressbar
                                            progressBarName="Delivery Performance"
                                            progressValue="4.5"
                                            grading="green"
                                        />
                                    </div>
                                    <div className='w-1/5 pl-2 text-center'>
                                        <Progressbar
                                            progressBarName="Pickup Performance"
                                            progressValue="4.5"
                                            grading="green"
                                        />
                                    </div>
                                    <div className='w-1/5 pl-2 text-center'>
                                        <Progressbar
                                            progressBarName="NDR Performance"
                                            progressValue="4.5"
                                            grading="green"
                                        />
                                    </div>
                                    <div className='w-1/5 pl-2 text-center'>
                                        <Progressbar
                                            progressBarName="RTO Performance"
                                            progressValue="4.5"
                                            grading="green"
                                        />
                                    </div>
                                    <div className='w-1/5 px-2 text-center'>
                                        <Progressbar
                                            progressBarName="Overall Rating"
                                            progressValue="4.5"
                                            grading="green"
                                        />
                                    </div>
                                </div>
                                <div className='border-t-2 mx-4 flex flex-row'>
                                    <div className=' flex flex-row my-2 px-2 w-1/2'>
                                        <div className='text-center'>
                                            <i className="fa-solid fa-location-dot"></i>
                                        </div>
                                        <div className='text-center align-middle justify-center my-1 mx-2'>
                                            <p className='text-xs align-middle justify-center'>Tracking Service: Real Time</p>
                                        </div>
                                    </div>
                                    <div className=' flex flex-row my-2 w-1/2 mx-4'>
                                        <div className='text-center'>
                                            <i className="fa-solid fa-file-signature"></i>
                                        </div>
                                        <div className='text-center align-middle justify-center my-1 ml-2'>
                                            <p className='text-xs align-middle justify-center'>POD: Instant on Delivery</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='w-1/4 flex flex-col'>
                            <div className='text-center mb-2'>
                                <h2 className='text-3xl font-medium'>₹ {Number((props.chargeForward + props.codCharge).toFixed(2))}</h2>
                            </div>
                            <div className='text-xs text-center'>
                                <p><strong>Estimated Pickup:</strong> {props.pickupDate}<br />
                                    <strong>Estimated Delivery:</strong> {props.deliveryDate}</p>
                            </div>
                            <div className='text-center bg-green-200 rounded-2xl  mt-4 text-xs mx-10 font-semibold'>
                                <p>RTO Charges: ₹ {props.chargeRTO}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='block lg:hidden w-full rounded-2xl shadow-2xl bg-white my-4 border border-gray-200'>
                <div className='flex flex-row items-center w-full py-2'>
                    <div className='w-1/12 mx-2'>
                        <input
                            type="radio"
                            id="selectedCourierId"
                            name="selectedCourierId"
                            className="w-4 h-4 my-2"
                            value={props.id}
                            required
                            onChange={props.selectedCourierIdOnChange}
                            />
                    </div>

                    <div className='w-11/12 flex flex-col justify-center'>
                        <div className='w-full flex flex-col items-center border-b-2'>
                            <img
                                className="rounded cursor-pointer align-middle block px-2 my-2"
                                alt={props.courierName}
                                width="100"
                                height="15"
                                src={props.logo}
                            />
                            <h2 className='text-base font-medium text-gray-700'>{props.courierName}</h2>
                        </div>

                        <div className='w-full flex flex-row py-2 text-center items-center border-b-2'>
                            <div className='w-5/12 border-r-2'>
                                <Progressbar
                                    progressBarName="Overall Rating"
                                    progressValue="4.5"
                                    grading="green"
                                />
                            </div>
                            <div className='w-7/12 flex flex-col px-2'>
                                <div className='flex flex-row my-1 items-center'>
                                    <i className="fa-solid fa-location-dot px-1"></i>

                                    <p className='text-xs align-middle justify-center'>Tracking Service: Real Time</p>
                                </div>
                                <div className='flex flex-row my-1 items-center'>
                                    <i className="fa-solid fa-file-signature px-1"></i>

                                    <p className='text-xs align-middle justify-center'>POD: Instant on Delivery</p>
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-col items-center w-full py-2'>
                            <div>
                                <h2 className='text-3xl font-medium'>₹ {props.chargeForward}</h2>
                            </div>
                            <div className='flex flex-col'>
                                <p className='text-xs'><strong>Estimated Pickup:</strong> {props.pickupDate}</p>
                                  <p className='text-xs'> <strong>Estimated Delivery:</strong> {props.deliveryDate}</p>
                            </div>
                            <div className='text-center bg-green-200 rounded-2xl text-xs font-semibold my-2 px-3 items-center py-2'>
                                <p>RTO Charges: ₹ {props.chargeRTO}</p>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}

export default CreateShipmentPage2
