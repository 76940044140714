import {SET_ALL_WALLET_TRANSACTION, SET_TRANSACTION } from "./actionTypes"

const allWalletData = []

const walletData = (state = allWalletData, action) => {
    switch (action.type) {
        case SET_ALL_WALLET_TRANSACTION:
            return [...action.data];
        case SET_TRANSACTION: 
        return [...allWalletData, action.data]
        default: return state

    }
}

export default walletData