import React from 'react'
import { useSelector } from 'react-redux'
import Breadcrumb from '../../components/common/Breadcrumb'
import avatar from '../../Assets/profile-pic.png'

const Profile = () => {

    const userDetails = useSelector((state) => state.userDetails)

    const capitalizeFirstWord = (input) => {
        if (typeof input !== 'undefined') {
            var word = input.trim().replace(/[\s\t]+/g, ' ');
            return word.split(' ').map(word => word[0].toUpperCase() + word.slice(1)).join(' ');
        };
    }


    return (
        <div className='flex flex-col mb-12 w-full text-gray-600'>
            <div className='w-full'>
                <Breadcrumb breadcrumbItem="Profile" title="Settings" />
            </div>

            <div className=' rounded-2xl shadow-2xl md:w-3/5 w-full flex md:flex-row flex-col bg-white'>
                <div className='md:w-2/5 w-full border-r-2 py-4 my-4 items-center flex flex-col' >
                    <div className=''>
                        <img src={userDetails.profilePhoto ? userDetails.profilePhoto : avatar} alt='profile' className='rounded-full w-48 h-48' />
                    </div>
                    <div className='text-center py-4'>
                        <h3>{capitalizeFirstWord(userDetails.name)}</h3>
                        <h3>{capitalizeFirstWord(userDetails.emailId)}</h3>
                    </div>
                </div>
                <div className='flex flex-col md:my-8 my-2 py-4 md:w-3/5 w-full px-4 justify-center md:text-base text-xs'>
                    <div className='flex flex-row justify-items-start h-6 my-3 px-3 items-baseline'>
                        <h3 className='font-medium w-1/2 '>Name</h3>
                        <h3 className='w-1/2 '>{capitalizeFirstWord(userDetails.name)}</h3>
                    </div>
                    <div className='flex flex-row justify-start h-6 md:text-base text-xs  my-3 items-baseline px-3'>
                        <h3 className='font-medium w-1/2 '>Email Id</h3>
                        <h3 className='w-1/2 '>{userDetails.emailId}</h3>
                    </div>
                    <div className='flex flex-row justify-around my-3 items-baseline px-3 md:text-base text-xs'>
                        <h3 className='font-medium w-1/2 '>Phone No</h3>
                        <h3 className='w-1/2 pt-1'>+91 {userDetails.mobNo}</h3>
                    </div>
                    <div className='flex flex-row justify-around my-3 items-baseline px-3 md:text-base text-xs'>
                        <h3 className='font-medium w-1/2 '>Company name</h3>
                        <h3 className='w-1/2 pt-1'>{capitalizeFirstWord(userDetails.companyName)}</h3>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile
