import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Breadcrumb from '../../components/common/Breadcrumb'
import { getTrackingData } from '../../Store/tracking/trackingActions'
// import delhiverlLogo from '../../Assets/delhiverylogo.png'

const PodPage = () => {

    const dispatch = useDispatch()

    const [selectedOrderData, setSelectedOrderData] = useState({})
    const [trackingdetails, setTrackingdetails] = useState([{ scanDate: '' }])
    const [podUrl, setPodUrl] = useState('')

    const params = useParams()
    const paramsAwb = Number(params.awb)

    const allOrdersData = useSelector((state) => state.ordersData.allOrders)
    const trackingData = useSelector((state) => state.trackingData)

    useEffect(() => {
        dispatch(getTrackingData(paramsAwb))
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if (trackingData.length !== 0) {
            setTrackingdetails(trackingData[0].scanData)
        }
    }, [trackingData])



    const getDateTime = (date) => {
        const dateFormat = new Date(date)
        const day = dateFormat.getDate()
        const month = dateFormat.getMonth() + 1
        const year = dateFormat.getFullYear()
        const time = dateFormat.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
        return `${day}/${month}/${year}, ${time} `
    }



    const selectedOrder = allOrdersData.find(obj => {
        return obj.awbNo === paramsAwb
    })

    useEffect(() => {
        if (typeof selectedOrder !== "undefined" && selectedOrder.length !== 0) {
            setSelectedOrderData(selectedOrder)
            console.log('thius', selectedOrder )
            getPodUrl(selectedOrder.companyName)
        }
        // eslint-disable-next-line
    }, [selectedOrder])

    useEffect(() => {
      if (typeof selectedOrderData.length !== 'undefined' && selectedOrderData.length !== 0) {
        console.log('selected order is and that', selectedOrderData)
        getPodUrl()
      }
       // eslint-disable-next-line
    }, [selectedOrder])
    

    const getPodUrl = (companyName) => {

       

        switch (companyName) {
            case 'Delhivery Surface':
                 getDelhiveryPod();
                 break;
            default: return alert('POD Not Available')
        }
    }

    const getDelhiveryPod = async () => {
        console.log('hsdohksdjh')

        const response = await fetch('https://api.couriero.in/delhivery/get-pod', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'auth-token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                awb: selectedOrder.awbNo
            })
        })

        const result = await response.json()
        setPodUrl(result.signature_url)
    }

    const podImage = (status) => {
        console.log("pod url is", status)
        switch (status) {
            case undefined:
                return (
                    <h3 className='text-xs font-medium mx-2 w-1/3'>POD Not Available(This feature is under development)</h3>
                    );
            default: return (
                <img src={status} alt='pod' className='w-48 h-52 py-4' />
            )
        }
    }


    return (
        <React.Fragment>
            <div className=' px-3 pb-12 overflow-y-auto z-20 bg-white h-full'>
                <div className='w-full'>
                    <Breadcrumb breadcrumbItem="Proof Of Delivery" title="Orders" />
                    {/* <img src={podUrl}/> */}
                    <div className='flex justify-center text-slate-600 my-4'>
                        <div className='rounded-2xl bg-white border w-1/2 py-4 p-4 flex flex-col shadow-2xl' >
                            <div className='flex flex-row items-center border-b-2'>
                                <div className='w-1/2'>
                                    <h2 className='text-lg font-semibold underline'>Courier Company</h2>
                                </div>
                                <div className='w-1/2 text-center flex flex-col items-center'>
                                    <img src={selectedOrderData.logo} alt={selectedOrderData.companyName} className='w-28 h-4' />
                                    <h3 className='text-base font-medium'>{selectedOrderData.companyName}</h3>

                                </div>
                            </div>

                            <div className='flex flex-col border-b-2 my-2'>
                                <div className='my-2'>
                                    <h2 className='text-lg font-semibold underline'>Order Details</h2>
                                </div>
                                <div className='flex flex-row '>
                                    <div className='flex flex-col w-1/2 border-r-2'>
                                        <div className='flex flex-row w-full items-center justify-items-start my-1'>
                                            <h3 className='text-xs font-medium w-1/2'>AWB No.</h3>
                                            <h3 className='text-xs font-normal w-1/2'>{selectedOrderData.awbNo}</h3>
                                        </div>
                                        <div className='flex flex-row w-full items-center justify-items-start my-1'>
                                            <h3 className='text-xs font-medium w-1/2'>Order Id</h3>
                                            <h3 className='text-xs font-normal w-1/2'>{selectedOrderData.orderId}</h3>
                                        </div>
                                    </div>
                                    <div className='w-1/2 ml-4'>
                                        <div className='flex flex-row w-full items-center justify-items-start my-1'>
                                            <h3 className='text-xs font-medium w-1/2'>Delivery date</h3>
                                            <h3 className='text-xs font-normal w-1/2'>{getDateTime(trackingdetails.slice(-1)[0].scanDate)}</h3>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='flex flex-col border-b-2 my-2'>
                                <div className='my-2'>
                                    <h2 className='text-lg font-semibold underline'>Consignee Details</h2>
                                </div>
                                <div>
                                    <div className='flex flex-row w-full items-center justify-items-start my-1'>
                                        <h3 className='text-xs font-medium mx-2 w-1/3'>Consignee name</h3>
                                        <h3 className='text-xs font-normal mx-2 w-2/3'>{selectedOrderData.consigneeName}</h3>
                                    </div>
                                    <div className='flex flex-row w-full items-center justify-items-start my-1'>
                                        <h3 className='text-xs font-medium mx-2 w-1/3'>Consignee Address</h3>
                                        <h3 className='text-xs font-normal mx-2 w-2/3'>{`${selectedOrderData.consigneeAddress}, ${selectedOrderData.consigneeCity}, ${selectedOrderData.consigneeState}`}</h3>
                                    </div>

                                </div>
                            </div>

                            <div className='flex flex-col border-b-2 my-2'>
                                <div className='my-2'>
                                    <h2 className='text-lg font-semibold underline'>Status</h2>
                                </div>
                                <div className='flex flex-row w-full items-center justify-items-start my-1'>
                                    <h3 className='text-xs font-medium mx-2 w-1/3'>Delivery Status</h3>
                                    <h3 className='text-xs font-medium mx-2 w-1/3'>Delivered</h3>
                                </div>
                                <div className='flex flex-row w-full items-center justify-items-start my-1'>
                                    <h3 className='text-xs font-medium mx-2 w-1/3'>Proof Of Delivery</h3>
                                    {podImage(podUrl)}
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default PodPage
