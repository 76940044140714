import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../components/common/Breadcrumb'

import CreateShipmentPage2 from './CreateShipmentPage2';
import AuthCode from 'react-auth-code-input';
import { useDispatch } from 'react-redux';
import { getAllWalletTransaction } from '../../Store/wallet/walletDataAction';
import { useNavigate } from 'react-router-dom';
import { getAllOrders } from '../../Store/orders/ordersActions';
import Toast from '../../components/toast/Toast';
import { ColorRing } from 'react-loader-spinner'




const CreateShipment = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()


    //------------------------------ Create Shipment Main DIV Items--------------------------------------//
    const [shipDetailsVisibility, setShipDetailsVisibility] = useState("block")
    const [companyVisibility, setCompanyVisibility] = useState("hidden")
    const [consigneeVisibility, setConsigneeVisibility] = useState("hidden")
    const [isLoading, setIsLoading] = useState(false);
    const [loaderLoading, setLoaderLoading] = useState(false)


    const [tab1, setTab1] = useState("blue")
    const [tab2, setTab2] = useState("orange")
    const [tab3, setTab3] = useState("orange")


    const handleSearchCourier = (e) => {
        e.preventDefault()
        setShipDetailsVisibility("hidden")
        setCompanyVisibility("block")
        setTab1("orange")
        setTab2("blue")
        fetchDeliveryPinCodeDetails()
        searchCourierCompany()
    }


    const handleBackShipDetails = (e) => {
        e.preventDefault()
        setShipDetailsVisibility("block")
        setCompanyVisibility("hidden")
        setTab1("blue")
        setTab2("orange")
        setSearchedCompanies([])
    }

    const handleShipWith = (e) => {
        e.preventDefault();
        setConsigneeVisibility("block");
        setCompanyVisibility("hidden");
        setTab3("blue");
        setTab2("orange");
        walletDebitAmount()
    }

    const handleBackSelectCompany = (e) => {
        e.preventDefault()
        setCompanyVisibility("block")
        setConsigneeVisibility("hidden")
        setTab2("blue")
        setTab3("orange")
        setIsLoading(false)

    }

    const handleCreateShipment = (e) => {
        e.preventDefault()
        setIsLoading(true)
        createShipment()
    }


    //------------------------------ Create Shipment Main DIV Items Ends--------------------------------------//

    //------------------------------ Create Shipment Page 1 Items--------------------------------------//

    const [addPickupAddressBoxVisibility, setAddPickupAddressBoxVisibility] = useState("hidden")
    const [pickupAddressDetailsSection, setPickupAddressDetailsSection] = useState('block')
    const [otpSection, setOtpSection] = useState("hidden")
    const [pickupAddressDetails, setPickupAddressDetails] = useState({ pickupAddressName: '', contactName: '', mobileNumber: '', email: '', addressLine1: '', addressLine2: '', pickupPincode: '', city: '', state: '' })
    const [sessionId, setSessionId] = useState("")
    const [otpResult, setOtpResult] = useState('');
    const [allPickupAddresses, setAllPickupAddresses] = useState([])
    const [createShipmentDetails, setCreateShipmentDetails] = useState({ deliveryPincode: '', orderType: '', productName: '', productQty: '', productAmount: '', selectedCourierId: '', consigneeName: '', consigneeEmail: '', consigneeMobile: '', consigneeAddress: '', orderId: '', price: 0, priceRTO: 0, codCharge: 0, consigneeCity: '', consigneeState: '' })
    const [weight, setWeight] = useState('')
    const [dimension, setDimension] = useState({ Length: '10', Bredth: '10', Height: '10' })
    const [volWeight, setVolWeight] = useState('')
    const [searchedCompanies, setSearchedCompanies] = useState([])
    const [selectedPickupAddress, setSelectedPickupAddress] = useState('')
    const [chargedWeight, setChargedWeight] = useState(0)
    const [toastArgs, setToastArgs] = useState(null)


    const showToast = (message, type) => {
        setToastArgs({
            message,
            type
        })
        setTimeout(() => {
            setToastArgs(null)
        }, 3000);
    }

    const fetchAllPickupAddresses = async () => {
        const response = await fetch('https://api.couriero.in/api/pickup-address/fetch-all-pickup-addresses', {
            method: 'GET',
            headers: {
                'auth-token': localStorage.getItem('token')
            }
        })
        const result = await response.json()
        setAllPickupAddresses(result)
    }

    useEffect(() => {
        fetchAllPickupAddresses()
    }, [])

    useEffect(() => {
        function calculateChargedWeight() {
            if (weight > volWeight) {
                setChargedWeight(weight)
            } else {
                setChargedWeight(volWeight)
            }
        }
        calculateChargedWeight()

    }, [weight, volWeight])

    const addNewPickupAddress = (e) => {
        e.preventDefault()
        setAddPickupAddressBoxVisibility("block")
    }

    const handleCloseButton = (e) => {
        e.preventDefault()
        setAddPickupAddressBoxVisibility("hidden")
    }


    const handleOnChange = (res) => {
        setOtpResult(res)
    }

    const handleDivOnClick = () => {
        setAddPickupAddressBoxVisibility("hidden")
        setOtpSection('hidden')
        setPickupAddressDetailsSection('block')
    }

    const handleNext = (e) => {
        e.preventDefault()
        setPickupAddressDetailsSection('hidden')
        setOtpSection('block')
        sendOtp(pickupAddressDetails.mobileNumber);
        console.log(pickupAddressDetails)
    }

    const sendOtp = async (mobileNumber) => {
        const response = await fetch('https://api.couriero.in/sms/smsOtp', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                mobileNumber: mobileNumber
            })
        });
        const result = await response.json()
        console.log(result)
        setSessionId(result.Details)
    }

    const handleBackButton = (e) => {
        e.preventDefault();
        setOtpSection('hidden')
        setPickupAddressDetailsSection('block')
    }

    const pickupAddressOnChange = (e) => {
        setPickupAddressDetails({ ...pickupAddressDetails, [e.target.name]: e.target.value })

    }

    useEffect(() => {
        const fetchPinCodeDetails = async () => {
            if (pickupAddressDetails.pickupPincode.length === 6) {
                const response = await fetch(`https://api.data.gov.in/resource/6176ee09-3d56-4a3b-8115-21841576b2f6?api-key=579b464db66ec23bdd0000014dc378d998af476b665db471066c40ec&format=json&filters%5Bpincode%5D=${pickupAddressDetails.pickupPincode}`, {
                    method: 'GET'
                })
                const result = await response.json()
                console.log(result)

                if (result.count >= 1) {
                    setPickupAddressDetails({ ...pickupAddressDetails, city: result.records[0].taluk, state: result.records[0].statename })
                }
            } else {
                setPickupAddressDetails({ ...pickupAddressDetails, city: '', state: '' })
            }

        };

        fetchPinCodeDetails()
        // eslint-disable-next-line
    }, [pickupAddressDetails.pickupPincode])

    const fetchDeliveryPinCodeDetails = async () => {
        if (createShipmentDetails.deliveryPincode.length === 6) {
            const response = await fetch(`https://api.data.gov.in/resource/6176ee09-3d56-4a3b-8115-21841576b2f6?api-key=579b464db66ec23bdd0000014dc378d998af476b665db471066c40ec&format=json&filters%5Bpincode%5D=${createShipmentDetails.deliveryPincode}`, {
                method: 'GET'
            })
            const result = await response.json()
            console.log(result)

            if (result.count >= 1) {
                setCreateShipmentDetails({ ...createShipmentDetails, consigneeCity: result.records[0].taluk, consigneeState: result.records[0].statename })
            }
        } else {
            setCreateShipmentDetails({ ...createShipmentDetails, city: '', state: '' })
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        verifyOtp(sessionId, otpResult)
    }

    const verifyOtp = async (sessionId, otpResult) => {
        const response = await fetch(`https://api.couriero.in/sms/verifyOtp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                sessionId: sessionId,
                otp: otpResult
            })
        })
        const result = await response.json();
        console.log(result)
        if (result.Status === 'Success') {
            createNewPickupAddress()
        }
    }

    const createNewPickupAddress = async () => {
        const response = await fetch('https://api.couriero.in/api/pickup-address/add-pickup-address', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'auth-token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                name: pickupAddressDetails.pickupAddressName,
                contactName: pickupAddressDetails.contactName,
                mobileNo: pickupAddressDetails.mobileNumber,
                email: pickupAddressDetails.email,
                addressLine1: pickupAddressDetails.addressLine1,
                addressLine2: pickupAddressDetails.addressLine2,
                pinCode: pickupAddressDetails.pickupPincode,
                city: pickupAddressDetails.city,
                state: pickupAddressDetails.state,
            })
        });


        const result = await response.json();

        if (result.status === 'success') {
            setAddPickupAddressBoxVisibility("hidden")
            setOtpSection('hidden')
            setPickupAddressDetailsSection('block')
            setPickupAddressDetails({ pickupAddressName: '', contactName: '', mobileNumber: '', email: '', addressLine1: '', addressLine2: '', pickupPincode: '', city: '', state: '' })
            setOtpResult("")
            setSessionId('')
            fetchAllPickupAddresses()
            showToast(`${result.message}`, `${result.status}`)
        } else if (result.status === 'error') {
            showToast(`${result.message}`, `${result.status}`)
        }
        //console.log(result)

    }

    const createShipmentOnChange = (e) => {
        setCreateShipmentDetails({ ...createShipmentDetails, [e.target.name]: e.target.value })
    }

    const handleWeightOnChange = (e) => {
        setWeight(e.target.value)
    }


    const handledimensionOnChange = (e) => {
        setDimension({ ...dimension, [e.target.name]: e.target.value })
    }

    useEffect(() => {
        setVolWeight(dimension.Length * dimension.Bredth * dimension.Height / 4000)
    }, [dimension])

    const handleSelectedPickupAddressOnChange = (e) => {
        const result = allPickupAddresses.find(obj => {
            return obj._id === e.target.value
        })
        setSelectedPickupAddress(result)
    }

    const walletDebitAmount = () => {
        const abc = searchedCompanies.find(obj => {
            return obj.id === createShipmentDetails.selectedCourierId
        })
        setCreateShipmentDetails({ ...createShipmentDetails, price: abc.chargeForward, priceRTO: abc.chargeRTO, codCharge: abc.codCharge });

    }

    const searchCourierCompany = async () => {
        setLoaderLoading(true)
        const response = await fetch('https://api.couriero.in/search-courier-companies/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'auth-token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                pickupPincode: selectedPickupAddress.pinCode,
                deliveryPincode: createShipmentDetails.deliveryPincode,
                weight: chargedWeight * 1000,
                orderType: createShipmentDetails.orderType,
                amount: createShipmentDetails.productAmount,
            })
        })

        const result = await response.json()
        console.log(result)

        if (result.status !== 'error') {
            setSearchedCompanies(result)
        } else {
            showToast(`${result.message}`, `${result.status}`)
        }

        setLoaderLoading(false)

        console.log('selected companies are', searchedCompanies)
    }

    const createShipment = async () => {
        const selectedCourierCompany = searchedCompanies.find(obj => {
            return obj.id === createShipmentDetails.selectedCourierId
        })

        const response = await fetch('https://api.couriero.in/create-shipment/', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                'auth-token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                companyName: selectedCourierCompany.courierName,
                companyId: createShipmentDetails.selectedCourierId,
                pickupAddressContactName: selectedPickupAddress.contactName,
                delhiveryWarehouseId: selectedPickupAddress.delhiveryWarehouseId,
                delhivery5kgWarehouseId: selectedPickupAddress.delhivery5kgWarehouseId ? selectedPickupAddress.delhivery5kgWarehouseId : '',
                pickupAddressMobileNo: selectedPickupAddress.mobileNo,
                pickupAddressName: selectedPickupAddress.name,
                pickupPinCode: selectedPickupAddress.pinCode,
                pickupAddress: `${selectedPickupAddress.addressLine1}, ${selectedPickupAddress.addressLine2}`,
                pickupAddressCity: selectedPickupAddress.city,
                pickupAddressState: selectedPickupAddress.state,
                productName: createShipmentDetails.productName,
                productAmount: createShipmentDetails.productAmount,
                productOrderType: createShipmentDetails.orderType,
                consigneeName: createShipmentDetails.consigneeName,
                consigneeEmail: createShipmentDetails.consigneeEmail,
                consigneeMobNo: createShipmentDetails.consigneeMobile,
                consigneeAddress: createShipmentDetails.consigneeAddress,
                deliveryPincode: createShipmentDetails.deliveryPincode,
                consigneeCity: createShipmentDetails.consigneeCity,
                consigneeState: createShipmentDetails.consigneeState,
                shipmentLength: dimension.Length,
                shipmentBredth: dimension.Bredth,
                shipmentHeight: dimension.Height,
                shipmentWeight: weight,
                shipmentVolWeight: volWeight,
                shipmentChargedWeight: chargedWeight,
                price: createShipmentDetails.price,
                priceRTO: createShipmentDetails.priceRTO,
                codCharge: createShipmentDetails.codCharge,
                logo: selectedCourierCompany.logo
            })
        })

        const result = await response.json()

        if (result.status === 'success') {
            showToast(`${result.message}`, 'success')
            dispatch(getAllOrders())
            dispatch(getAllWalletTransaction())
            setTimeout(() => {
                navigate('/all-orders')
            }, 1000);

        } else if (result.status === 'error') {
            showToast(`${result.message}`, 'error')
        }

    }

    //------------------------------ Create Shipment Page 1 Items Ends--------------------------------------//


    return (
        <React.Fragment>
            <div className='flex flex-col text-gray-600 mb-12 w-full '>
                <div className='w-full'>
                    <Breadcrumb breadcrumbItem="Create Shipment" title="Orders" />
                </div>
                <div className=' w-full bg-orange-200 rounded-3xl flex md:flex-row flex-col justify-evenly'>
                    <div className={`bg-${tab1}-200 px-8 py-2 rounded-3xl `} >
                        <span className='fa-solid fa-1 w-9 h-9 leading-8 border-2 border-blue-800 bg-white rounded-3xl text-center text-blue-700 mr-2'></span>
                        Shipment Details
                    </div>
                    <div className={`bg-${tab2}-200 px-8 py-2 rounded-3xl`}>
                        <span className='fa-solid fa-2 w-9 h-9 leading-8 border-2 border-blue-800 bg-white rounded-3xl text-center text-blue-700 mr-2'></span>
                        Select Company
                    </div>
                    <div className={`bg-${tab3}-200 px-8 py-2 rounded-3xl`}>
                        <span className='fa-solid fa-3 w-9 h-9 leading-8 border-2 border-blue-800 bg-white rounded-3xl text-center text-blue-700 mr-2'></span>
                        Consignee Details
                    </div>
                </div>
                {toastArgs !== null && <Toast toastArgs={toastArgs} />}

                <div className={`md:p-2 md:mt-2 mt-1 ${shipDetailsVisibility} w-full`}>
                    <div className='text-gray-500'>
                        <form onSubmit={handleSearchCourier} >
                            <div className='bg-white rounded-2xl shadow-2xl w-full my-4 flex flex-col'>
                                <div className='flex flex-col mx-8 border-b-2 py-10'>
                                    <div>
                                        <h4 className='text-xl font-semibold text-gray-500 md:pl-4 py-2 underline'>Pickup Details</h4>
                                    </div>
                                    <div className='flex md:flex-row flex-col md:items-center'>
                                        <div className='flex flex-col md:w-6/12 w-full md:ml-4 my-2'>
                                            <div className='mb-2 w-full'>
                                                <label className='font-medium'>Select Pickup Address</label>
                                            </div>
                                            <div className='flex md:flex-row flex-col items-center w-full'>
                                                <div className='md:w-7/12 w-full' >
                                                    <select
                                                        className='w-full text-xs font-normal border-solid border-2 border-gray-300 rounded-2xl py-1 px-2'
                                                        onChange={handleSelectedPickupAddressOnChange}
                                                        defaultValue=''
                                                        required
                                                    >
                                                        <option value="" disabled>Select your Pickup Address</option>
                                                        {allPickupAddresses.map((element) => { return <option key={element._id} value={element._id} >{`${element.name} (${element.addressLine1})`}</option> })}
                                                    </select>
                                                </div>
                                                <div className='md:ml-3 my-2 md:w-5/12 w-full'>
                                                    <button className='rounded-2xl bg-blue-500 text-xs px-2 py-2 text-white font-medium' onClick={addNewPickupAddress}>
                                                        + Add New Pickup Address
                                                    </button>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='flex flex-col md:w-3/12 w-full my-2'>
                                            <div className='mb-2'>
                                                <label htmlFor="deliveryPincode" className=' font-medium'>Delivery Pincode</label>
                                            </div>
                                            <div>
                                                <input
                                                    type="text"
                                                    id='deliveryPincode'
                                                    className='rounded-2xl border-2 text-sm border-solid border-gray-300 px-4 py-1'
                                                    placeholder="Enter Delivery Pin Code"
                                                    required
                                                    maxLength={6}
                                                    pattern='[0-9]{6}'
                                                    name='deliveryPincode'
                                                    onChange={createShipmentOnChange}
                                                    value={createShipmentDetails.deliveryPincode}
                                                />
                                            </div>
                                        </div>

                                        <div className='flex flex-col md:w-3/12 w-full my-2'>
                                            <div className='mb-4'>
                                                <label className='font-medium'>Order Type</label>
                                            </div>
                                            <div className='flex flex-row items-center' >
                                                <div className='flex flex-row items-center'>
                                                    <input
                                                        type="radio"
                                                        id="cod"
                                                        className="rounded-full border border-solid border-gray-300 cursor-pointer"
                                                        name='orderType'
                                                        value='cod'
                                                        onChange={createShipmentOnChange}
                                                        required
                                                    />
                                                    <label
                                                        className="cursor-pointer text-sm mx-4"
                                                        htmlFor="cod"
                                                    >
                                                        COD
                                                    </label>
                                                </div>
                                                <div className='flex flex-row items-center ml-2'>
                                                    <input
                                                        type="radio"
                                                        id="prepaid"
                                                        className=" rounded-full border border-solid border-gray-300 cursor-pointer"
                                                        name='orderType'
                                                        value='prepaid'
                                                        onChange={createShipmentOnChange}
                                                        required
                                                    />
                                                    <label
                                                        className="  cursor-pointer text-sm  mx-4"
                                                        htmlFor="prepaid"
                                                    >
                                                        Prepaid
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex flex-col mx-8 border-b-2 py-10'>
                                    <div>
                                        <h4 className='text-xl font-semibold text-gray-500 md:pl-4 py-2 underline'>Product Details</h4>
                                    </div>
                                    <div className='flex md:flex-row flex-col items-center'>
                                        <div className='flex flex-col md:w-1/3 md:ml-4 w-full my-2'>
                                            <div className='mb-2'>
                                                <label className='font-medium'>Product name</label>
                                            </div>
                                            <div>
                                                <input
                                                    type="text"
                                                    className="w-10/12 px-3 py-1 text-sm font-normal border-2 border-solid border-gray-300 rounded-2xl"
                                                    id="productName"
                                                    placeholder="Enter Name of the Product"
                                                    onChange={createShipmentOnChange}
                                                    name='productName'
                                                    value={createShipmentDetails.productName}
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className='flex flex-col md:w-1/3 md:ml-4 w-full my-2 '>
                                            <div className='mb-2'>
                                                <label className='font-medium'>Quantity</label>
                                            </div>
                                            <div>
                                                <input
                                                    type="number"
                                                    className="w-10/12 px-3 py-1 text-sm font-normal border-2 border-solid border-gray-300 rounded-2xl"
                                                    id="productQty"
                                                    placeholder="Enter Number of boxes of Shipment"
                                                    maxLength={2}
                                                    pattern='[1-9]{2}'
                                                    onChange={createShipmentOnChange}
                                                    value={createShipmentDetails.productQty}
                                                    name='productQty'
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className='flex flex-col md:w-1/3 md:ml-4 w-full my-2'>
                                            <div className='mb-2'>
                                                <label className='font-medium'>Product Amount</label>
                                            </div>
                                            <div>
                                                <input
                                                    type="number"
                                                    className="w-10/12 px-3 py-1 text-sm font-normal border-2 border-solid border-gray-300 rounded-2xl"
                                                    id="productAmount"
                                                    placeholder="Enter Total Amount of the Product"
                                                    onChange={createShipmentOnChange}
                                                    value={createShipmentDetails.productAmount}
                                                    name='productAmount'
                                                    required
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='flex flex-col mx-8  py-10'>
                                    <div>
                                        <h4 className='text-xl mb-2 font-semibold text-gray-500 underline md:pl-4 py-2'>Weight & dimension Details</h4>
                                    </div>
                                    <div className='flex md:flex-row flex-col items-center'>
                                        <div className='flex flex-col md:w-1/3 md:ml-4 w-full my-2'>
                                            <div className='mb-2'>
                                                <label className='font-medium'>Shipment Weight (in Kg)</label>
                                            </div>
                                            <div>
                                                <input
                                                    type="number"
                                                    className="w-10/12 px-3 py-1 text-sm font-normal border-2 border-solid border-gray-300 rounded-2xl"
                                                    id="weight"
                                                    placeholder="Enter Weight of the Shipment in Kg"
                                                    name='weight'
                                                    onChange={handleWeightOnChange}
                                                    value={weight}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className='flex flex-col md:w-1/3 md:ml-4 w-full my-2'>
                                            <div className='mb-2'>
                                                <label className='font-medium'>Dimensions (in cm)</label>
                                            </div>
                                            <div className='flex flex-row items-center justify-between w-full'>
                                                <input
                                                    type="number"
                                                    className="w-3/12 text-sm font-normal border-2 border-solid border-gray-300 text-center rounded-2xl py-1"
                                                    id="length"
                                                    placeholder="Length"
                                                    name='Length'
                                                    onChange={handledimensionOnChange}
                                                    value={dimension.Length}
                                                    required
                                                />
                                                <span className='mx-1'>X</span>
                                                <input
                                                    type="number"
                                                    className="w-3/12 text-sm font-normal border-2 border-solid border-gray-300 text-center rounded-2xl py-1"
                                                    id="Bredth"
                                                    placeholder="Breadth"
                                                    name='Bredth'
                                                    onChange={handledimensionOnChange}
                                                    value={dimension.Bredth}
                                                    required
                                                />
                                                <span className='mx-1'>X</span>
                                                <input
                                                    type="number"
                                                    className="w-3/12 text-sm font-normal border-2 border-solid border-gray-300 text-center rounded-2xl py-1"
                                                    id="Height"
                                                    placeholder="Height"
                                                    name='Height'
                                                    onChange={handledimensionOnChange}
                                                    value={dimension.Height}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className='flex flex-col md:w-1/3 md:ml-10 w-full my-2'>
                                            <div className='mb-2'>
                                                <label className='font-medium'>Volumetric Weight (in Kg)</label>
                                            </div>
                                            <div>
                                                <input
                                                    type="text"
                                                    className="w-10/12 px-3 py-1 text-sm font-normal border-2 border-solid border-gray-300 rounded-2xl"
                                                    id="volWeight"
                                                    disabled
                                                    value={volWeight}
                                                    name='volWeight'
                                                />
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div className='flex justify-end'>
                                <button
                                    className='bg-blue-500 rounded-2xl px-3 py-1 mt-6 text-sm cursor-pointer text-white font-medium mb-8'
                                    type='submit'
                                >
                                    Search Courier Company
                                </button>
                            </div>
                        </form>
                    </div>

                    <div className={`${addPickupAddressBoxVisibility} fixed inset-0  z-[1000] flex justify-center items-center`}>
                        <div className='opacity-60 blur-lg fixed w-full h-full bg-black' onClick={handleDivOnClick}>
                        </div>

                        <div className='bg-white md:w-[48rem] w-10/12 pb-4 rounded-2xl shadow-2xl text-gray-500 block absolute'>
                            <div className='flex flex-col'>
                                <div className='text-center p-4 m-1  bg-blue-200 mt-1 rounded-2xl'>
                                    <h3 className='text-lg font-bold'>Add Pickup Address</h3>
                                </div>
                                <div>
                                    <form onSubmit={handleNext}>
                                        <div className={`${pickupAddressDetailsSection}`}>
                                            <div className='flex flex-col p-4 justify-center '>
                                                <div className='ml-2 mt-2'>
                                                    <h5 className='text-xl font-medium'>Contact Info</h5>
                                                </div>
                                                <div className='flex md:flex-row flex-col items-center mx-2 mt-2'>
                                                    <div className='md:w-1/4 w-full ml-2 flex flex-col' >
                                                        <label className='text-sm font-semibold'>Address Name</label>
                                                        <input
                                                            type='text'
                                                            className='text-xs rounded-2xl border border-solid border-gray-300 px-6 py-1'
                                                            placeholder='Enter Address Name'
                                                            id='pickupAddressName'
                                                            name='pickupAddressName'
                                                            onChange={pickupAddressOnChange}
                                                            value={pickupAddressDetails.pickupAddressName}
                                                            required
                                                        />
                                                    </div>
                                                    <div className='md:w-1/4 w-full my-2 flex flex-col ml-2'>
                                                        <label className='text-sm font-semibold'>Contact Name</label>
                                                        <input
                                                            type='text'
                                                            className='text-xs rounded-2xl border border-solid border-gray-300 px-6 py-1'
                                                            placeholder='Enter Contact Name'
                                                            id='contactName'
                                                            name='contactName'
                                                            onChange={pickupAddressOnChange}
                                                            value={pickupAddressDetails.contactName}
                                                            required
                                                        />
                                                    </div>
                                                    <div className='md:w-1/4 w-full my-2 flex flex-col ml-2'>
                                                        <label className='text-sm font-semibold'>Mobile No.</label>
                                                        <input
                                                            type='text'
                                                            className=' text-xs rounded-2xl border border-solid border-gray-300 px-6 py-1'
                                                            placeholder='Enter Mobile Number'
                                                            id='mobileNumber'
                                                            name='mobileNumber'
                                                            minLength={10}
                                                            maxLength={10}
                                                            pattern='[6-9]{1}[0-9]{9}'
                                                            onChange={pickupAddressOnChange}
                                                            value={pickupAddressDetails.mobileNumber}
                                                            required
                                                        />
                                                    </div>
                                                    <div className='md:w-1/4 w-full my-2 flex flex-col ml-2'>
                                                        <label className='text-sm font-semibold'>Email Id</label>
                                                        <input
                                                            type='email'
                                                            className=' text-xs rounded-2xl border border-solid border-gray-300 px-6 py-1'
                                                            placeholder='Enter Email Id'
                                                            id='email'
                                                            name='email'
                                                            onChange={pickupAddressOnChange}
                                                            value={pickupAddressDetails.email}
                                                            required
                                                        />
                                                    </div>
                                                </div>
                                                <div className='flex flex-col'>
                                                    <div className='ml-2 mt-4'>
                                                        <h5 className='text-xl font-medium'>Address Details</h5>
                                                    </div>
                                                    <div className='flex md:flex-row flex-col mx-2 mt-2'>
                                                        <div className='md:w-1/2 w-full ml-2 flex flex-col'>
                                                            <label className='text-sm font-semibold'>Address Line 1</label>
                                                            <input
                                                                type='text'
                                                                className=' text-xs rounded-2xl border border-solid border-gray-300 md:px-12 px-6 md:mr-12 py-1'
                                                                placeholder='Enter Address Line 1'
                                                                id='addressLine1'
                                                                name='addressLine1'
                                                                onChange={pickupAddressOnChange}
                                                                value={pickupAddressDetails.addressLine1}
                                                                required
                                                            />
                                                        </div>
                                                        <div className='md:w-1/2 w-full ml-2 my-2 flex flex-col'>
                                                            <label className='text-sm font-semibold'>Address Line 2</label>
                                                            <input
                                                                type='text'
                                                                className=' text-xs rounded-2xl border border-solid border-gray-300 md:px-12 md:mr-12 px-6 py-1'
                                                                placeholder='Enter Address Line 2'
                                                                id='addressLine2'
                                                                name='addressLine2'
                                                                onChange={pickupAddressOnChange}
                                                                value={pickupAddressDetails.addressLine2}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='flex md:flex-row flex-col md:mt-8 mt-4 my-2 justify-center'>
                                                        <div className='md:w-1/3 w-full md:mx-4 flex flex-col'>
                                                            <label className='text-sm font-semibold mx-4'>Pin Code</label>
                                                            <input
                                                                type='text'
                                                                className='text-xs rounded-2xl border border-solid border-gray-300 px-2 mx-4 py-1'
                                                                placeholder='Enter Pin Code'
                                                                id='pickupPincode'
                                                                name='pickupPincode'
                                                                minLength={6}
                                                                maxLength={6}
                                                                pattern='[1-9]{1}[0-9]{5}'
                                                                value={pickupAddressDetails.pickupPincode}
                                                                onChange={pickupAddressOnChange}
                                                                required
                                                            />
                                                        </div>
                                                        <div className='md:w-1/3 w-full md:ml-2 my-2 flex flex-col'>
                                                            <label className='text-sm font-semibold mx-4'>City</label>
                                                            <input
                                                                type='text'
                                                                className=' text-xs rounded-2xl border border-solid border-gray-300 px-2 mx-4 py-1'
                                                                id='pickupCity'
                                                                value={pickupAddressDetails.city}
                                                                disabled
                                                                required
                                                            />
                                                        </div>
                                                        <div className='md:w-1/3 md:ml-2 w-full my-2 flex flex-col'>
                                                            <label className='text-sm font-semibold mx-4'>State</label>
                                                            <input
                                                                type='text'
                                                                className=' text-xs rounded-2xl border border-solid border-gray-300 px-2 mx-4 py-1'
                                                                id='pickupState'
                                                                value={pickupAddressDetails.state}
                                                                disabled
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='flex flex-col text-center md:mt-6'>
                                                    <div className=''>
                                                        <button onClick={handleCloseButton} className='bg-blue-500 text-white rounded-2xl mt-8 text-center px-3 py-1 mx-2 hover:bg-blue-600 text-sm'>Close</button>
                                                        <button type='submit' className='bg-blue-500 text-white rounded-2xl mt-8 text-center px-4 py-1 mx-2 hover:bg-blue-600 text-sm disabled:opacity-50' disabled={!pickupAddressDetails.city}>Next</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                                <form onSubmit={handleSubmit}>
                                    <div className={`${otpSection} w-full`}>
                                        <div className='flex flex-col  items-center text-center h-full md:p-28 p-1'>
                                            <h4 className='text-lg font-medium mb-4'>Enter OTP</h4>
                                            <AuthCode
                                                onChange={handleOnChange}
                                                inputClassName={'border border-solid mx-1 py-1 md:w-12 w-10  border-gray-300 text-center rounded-xl'}
                                                allowedCharacters={'numeric'}
                                                autoFocus={false}
                                            />
                                            <div className='flex flex-row'>
                                                <button onClick={handleBackButton} className='mx-2 bg-blue-500 text-white rounded-2xl mt-8 text-center px-4 py-1 hover:bg-blue-600 text-sm'>Back</button>
                                                <button type='submit' className='mx-2 bg-blue-500 text-white rounded-2xl mt-8 text-center px-4 py-1 hover:bg-blue-600 text-sm'>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div >
                    </div>
                </div>

                {
                    loaderLoading && (
                        <div className='flex items-center justify-center py-20'>
                            <ColorRing
                                visible={true}
                                height="80"
                                width="80"
                                ariaLabel="blocks-loading"
                                wrapperStyle={{}}
                                wrapperClass="blocks-wrapper"
                                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
                            />
                        </div>
                    )
                }

                {
                    !loaderLoading && searchedCompanies.length !== 0 && (
                        <div className={`${companyVisibility} p-4 mt-3`}>
                            <form onSubmit={handleShipWith}>
                                {searchedCompanies.map((element) => {
                                    return <CreateShipmentPage2
                                        courierName={element.courierName}
                                        key={element.id}
                                        id={element.id}
                                        chargeForward={element.chargeForward}
                                        codCharge={element.codCharge}
                                        logo={element.logo}
                                        chargeRTO={element.chargeRTO}
                                        deliveryDate={element.deliveryDate}
                                        pickupDate={element.pickupDate}
                                        minWeight={element.minWeight}
                                        selectedCourierIdOnChange={createShipmentOnChange}
                                    />
                                })}
                                <div className='flex flex-row items-center justify-end'>
                                    <button
                                        className='bg-blue-500 rounded-2xl md:px-3 px-2 py-1 md:mx-2 mx-1 md:text-sm text-xs cursor-pointer text-white font-medium mb-8'
                                        onClick={handleBackShipDetails}
                                    >
                                        Back to Shipment Details
                                    </button>
                                    <button
                                        className='bg-blue-500 rounded-2xl md:px-3 px-2 py-1 md:mx-2 mx-1 md:text-sm text-xs cursor-pointer text-white font-medium mb-8'
                                        type='submit'
                                    >
                                        Next
                                    </button>
                                </div>
                            </form>
                        </div>
                    )
                }



                <div>
                    <div className={`${consigneeVisibility} p-4 my-3`}>
                        <form onSubmit={handleCreateShipment}>
                            <div className='rounded-2xl shadow-2xl border border-gray-200 bg-white flex flex-col  '>
                                <div className='flex flex-col px-2 py-4'>
                                    <div className='w-full mx-2' >
                                        <h4 className='text-xl mb-2 font-semibold text-gray-500 underline'>Consignee Details</h4>
                                    </div>
                                    <div className='flex flex-col w-full my-2'>
                                        <div className='flex md:flex-row flex-col items-center mx-4 my-2'>
                                            <div className='md:w-1/3 w-full flex flex-col'>
                                                <label className='mb-2 font-medium md:text-base text-sm my-2'>Consignee name</label>
                                                <input
                                                    type="text"
                                                    className="w-full px-3 py-1 text-sm font-normal border border-solid border-gray-200 rounded-2xl mr-4"
                                                    id="consigneeName"
                                                    placeholder="Enter Name of the Consignee"
                                                    name='consigneeName'
                                                    onChange={createShipmentOnChange}
                                                    value={createShipmentDetails.consigneeName}
                                                    required
                                                />
                                            </div>
                                            <div className='md:w-1/3 w-full flex flex-col mx-4'>
                                                <label className='mb-2 font-medium md:text-base text-sm my-2'>Consignee Email Id</label>
                                                <input
                                                    type="email"
                                                    className="w-full px-3 py-1 text-sm font-normal border border-solid border-gray-200 rounded-2xl"
                                                    id="consigneeEmail"
                                                    placeholder="Enter Email Id Of The Consignee"
                                                    name='consigneeEmail'
                                                    onChange={createShipmentOnChange}
                                                    value={createShipmentDetails.consigneeEmail}
                                                />
                                            </div>
                                            <div className='md:w-1/3 w-full flex flex-col'>
                                                <label className='mb-2 font-medium md:text-base text-sm my-2'>Consignee Mobile Phone No.</label>
                                                <input
                                                    type="text"
                                                    className="w-full px-3 py-1 text-sm font-normal border border-solid border-gray-200 rounded-2xl"
                                                    id="consigneeMobile"
                                                    placeholder="Enter Mobile Number Of The Consignee"
                                                    name='consigneeMobile'
                                                    pattern='[0-9]{10}'
                                                    maxLength={10}
                                                    onChange={createShipmentOnChange}
                                                    value={createShipmentDetails.consigneeMobile}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className='flex flex-row items-center mx-4 my-2'>
                                            <div className='w-full flex flex-col'>
                                                <label className='mb-2 font-medium md:text-base text-sm my-2'>Consignee Address</label>
                                                <input
                                                    type="text"
                                                    className="md:w-2/3 w-full px-3 py-1 text-sm font-normal border border-solid border-gray-200 rounded-2xl mr-4"
                                                    id="consigneeAddress"
                                                    placeholder="Enter Address of the Consignee"
                                                    name='consigneeAddress'
                                                    onChange={createShipmentOnChange}
                                                    value={createShipmentDetails.consigneeAddress}
                                                    required
                                                />
                                            </div>
                                        </div>

                                        <div className='flex md:flex-row flex-col items-center mx-4 my-2'>
                                            <div className='md:w-1/3 w-full flex flex-col'>
                                                <label className='mb-2 font-medium'>Pin Code</label>
                                                <input
                                                    disabled
                                                    type="text"
                                                    className="w-full px-3 py-1 text-sm font-normal border border-solid border-gray-200 rounded-2xl"
                                                    id="productAmount"
                                                    placeholder={createShipmentDetails.deliveryPincode}
                                                />
                                            </div>
                                            <div className='md:w-1/3 w-full flex flex-col mx-4'>
                                                <label className='mb-2 font-medium'>City</label>
                                                <input
                                                    disabled
                                                    type="text"
                                                    className="w-full px-3 py-1 text-sm font-normal border border-solid border-gray-200 rounded-2xl"
                                                    id="productAmount"
                                                    placeholder={createShipmentDetails.consigneeCity}
                                                />
                                            </div>
                                            <div className='md:w-1/3 w-full flex flex-col'>
                                                <label className='mb-2 font-medium'>State</label>
                                                <input
                                                    disabled
                                                    type="text"
                                                    className="w-full px-3 py-1 text-sm font-normal border border-solid border-gray-200 rounded-2xl"
                                                    id="productAmount"
                                                    placeholder={createShipmentDetails.consigneeState}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div className={`block mt-4 ${createShipmentDetails.productAmount <= 50000 ? 'hidden' : 'block'}`}>
                                    <div className='flex flex-col px-2 py-2'>
                                        <div className='mx-2'>
                                            <h4 className='text-xl mb-2 font-semibold text-gray-500 underline'>E-Way Bill Information</h4>
                                        </div>
                                        <div className='flex flex-col mx-4 my-2'>
                                            <label className='mb-2 font-medium'>E-way Bill No.</label>
                                            <input
                                                type="text"
                                                className=" md:w-1/3 w-full px-3 py-1 text-sm font-normal border border-solid border-gray-200 rounded-2xl"
                                                id="eWayBill"
                                                placeholder="Enter E-Way Bill Number"
                                                required={createShipmentDetails.productAmount <= 50000 ? false : true}
                                            />
                                        </div>
                                    </div>
                                </div>


                            </div>
                            <div className='flex justify-end my-6'>
                                <button
                                    className='bg-blue-500 rounded-2xl px-3 py-1 mx-2 md:text-sm text-xs cursor-pointer text-white font-medium mb-8'
                                    onClick={handleBackSelectCompany}
                                >
                                    Back to Select Company
                                </button>
                                <button
                                    className='bg-blue-500 rounded-2xl px-3 py-1 md:text-sm text-xs cursor-pointer text-white font-medium mb-8'
                                    type='submit'
                                    disabled={isLoading}
                                >
                                    {isLoading ? 'Creating...' : 'Create Shipment'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>









            </div>

        </React.Fragment>
    )
}

export default CreateShipment
