import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import AuthCode from 'react-auth-code-input';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import Toast from '../../components/toast/Toast'

import logo from "../../Assets/logo.png"
//import fblogo from "../../Assets/fblogo.png"



const SignUp = () => {
    const navigate = useNavigate()

    const showToast = (message, type) => {
        setToastArgs({
            message,
            type
        })
        setTimeout(() => {
            setToastArgs(null)
        }, 3000);
    }

    const [signUpDetail, setSignUpDetail] = useState({ name: "", email: "", password: "", mobileNo: "", profilePic: "" })
    const [otpResult, setOtpResult] = useState("")
    const [inputDisable, setInputDisable] = useState(false)
    const [toastArgs, setToastArgs] = useState(null)


    const onChange = (e) => {
        setSignUpDetail({ ...signUpDetail, [e.target.name]: e.target.value })
    }

    const handleOtpChange = (res) => {
        setOtpResult(res)
    }

    const [sessionId, setSessionId] = useState("")

    const [emailSection, setEmailSection] = useState("block")
    const [otpSection, setOtpSection] = useState("hidden")

    const handleOtpSection = (e) => {
        e.preventDefault();
        setEmailSection("hidden");
        setOtpSection("block")
        sendOtp(signUpDetail.mobileNo);
    }

    const sendOtp = async (mobileNumber) => {
        const response = await fetch('https://api.couriero.in/sms/smsOtp', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                mobileNumber: mobileNumber
            })
        });
        const result = await response.json()
        console.log(result)
        setSessionId(result.Details)
    }

    const createUser = async () => {
        const response3 = await fetch('https://api.couriero.in/api/auth/create-user', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                name: signUpDetail.name,
                emailId: signUpDetail.email,
                password: signUpDetail.password,
                mobNo: signUpDetail.mobileNo
            })
        });

        const result3 = await response3.json();
        console.log(result3)
        if (result3.AuthToken) {
            localStorage.setItem('token', result3.AuthToken)
            navigate('/')
        }
    }

    const verifyOtp = async (sessionId, otpResult) => {
        const response = await fetch(`https://api.couriero.in/sms/verifyOtp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                sessionId: sessionId,
                otp: otpResult
            })
        })
        const result = await response.json();
        console.log('the result is', result)
        if (result.Status === 'Success') {
            createUser()
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        verifyOtp(sessionId, otpResult)
    }


    const handleSignUp = async (credentialResponse) => {
        const response = await fetch('https://api.couriero.in/google/signup/callback', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                credentialResponse: credentialResponse
            })

        })

        const result = await response.json()

        if (result.status === 'new signup') {
            setSignUpDetail({
                email: result.data.email,
                name: result.data.name,
                profilePic: result.data.profilePic,
                password: result.data.jti,
                mobileNo: ""
            })
            setInputDisable(true)
            showToast(`${result.message}`, 'success')


        } else if (result.status === 'login') {
            showToast(`${result.message}`, 'success')
            localStorage.setItem('token', result.AuthToken)
            navigate('/')
        }
    }




    return (
        <React.Fragment>
            <div className='w-full h-full fixed items-center z-0 bg-gray-50 flex flex-col align-middle overflow-y-scroll'>
                <img src={logo} className=' w-60 h-10 lg:my-10 my-5' alt='logo' />
                <div className='bg-white lg:w-[30rem] lg:h-[33rem] relative rounded-2xl shadow-2xl align-middle p-2 mb-8'>
                {toastArgs !== null && <Toast toastArgs={toastArgs}/>} 
                    <div className='block'>
                        <h3 className='text-center my-4 font-bold text-gray-500 text-lg' >Sign Up To Couriero with</h3>
                        <div className='flex flex-row justify-evenly mt-8 mb-2'>
                            <div>
                                <GoogleOAuthProvider clientId="86166203287-69tfbeagj3944a4b0crs255smug2a7v2.apps.googleusercontent.com">
                                    <GoogleLogin
                                        onSuccess={credentialResponse => {
                                            handleSignUp(credentialResponse)
                                        }}
                                        shape='pill'
                                        size='medium'
                                        text='signup_with'
                                    />
                                </GoogleOAuthProvider>
                            </div>

                            {/* <div className='rounded-2xl border flex-row flex justify-center align-middle px-4 py-1 cursor-pointer'>
                                <img src={fblogo} className="w-5 h-5 mr-2" alt='google' />
                                <p className='align-middle text-sm '>Facebook</p>
                            </div> */}
                        </div>
                        <div className="w-full flex items-center justify-between py-2">
                            <hr className="w-full bg-gray-600" />
                            <p className="text-base font-medium leading-4 px-2.5 text-gray-500">OR</p>
                            <hr className="w-full bg-gray-600  " />
                        </div>
                    </div>
                    <form onSubmit={handleOtpSection}>
                        <div className={`${emailSection}`}>
                            <div className='mx-6 mt-2'>
                                <div className='mb-2'>
                                    <label htmlFor='name' className="text-sm font-medium leading-none text-gray-800">Name</label>
                                    <input name='name' disabled={inputDisable} onChange={onChange} value={signUpDetail.name} id='name' placeholder="Enter Your Name" type="text" className="border rounded-2xl text-xs font-medium leading-none text-gray-600 py-2 w-full pl-3 mt-2" required />
                                </div>
                                <div className='mb-2'>
                                    <label htmlFor='email' className="text-sm font-medium leading-none text-gray-800">Email</label>
                                    <input name='email' disabled={inputDisable} onChange={onChange} value={signUpDetail.email} id='email' placeholder="Enter email adress" type="email" className="border rounded-2xl text-xs font-medium leading-none text-gray-600 py-2 w-full pl-3 mt-2" required />
                                </div>
                                <div className='mb-2'>
                                    <label htmlFor='password' className="text-sm font-medium leading-none text-gray-800">Password</label>
                                    <input name='password' disabled={inputDisable} onChange={onChange} value={signUpDetail.password} id='password' placeholder="Create Your Password" type="password" className="border rounded-2xl text-xs font-medium leading-none text-gray-600 py-2 w-full pl-3 mt-2" required />
                                </div>
                                <div className='mb-2'>
                                    <div className='mb-2'>
                                        <label htmlFor='mobileNo' className="text-sm font-medium leading-none text-gray-800">Mobile No</label>
                                        <input name='mobileNo' onChange={onChange} value={signUpDetail.mobileNo} id='mobileNo' placeholder="Enter Your Mobile Number" type="number" className="border rounded-2xl text-xs font-medium leading-none text-gray-600 py-2 w-full pl-3 mt-2" required minLength={10} maxLength={10} />
                                    </div>
                                </div>
                            </div>
                            <div className="mt-4 mx-20">
                                <button type="submit" className=" text-sm font-semibold leading-none text-white border rounded-2xl hover:bg-blue-600 bg-blue-500 py-2 w-full">
                                    Sign Up
                                </button>
                            </div>
                        </div>
                    </form>


                    <form onSubmit={handleSubmit}>
                        <div className={`${otpSection} mt-16`}>
                            <div className='lg:mx-6 mt-2 text-center'>
                                <h4 className='mb-4 '>Enter OTP</h4>
                                <AuthCode
                                    onChange={handleOtpChange}
                                    inputClassName={'border border-solid mx-1 py-1 w-12 border-gray-300 text-center rounded-xl'}
                                    allowedCharacters={'numeric'}
                                />
                            </div>
                            <div className="mt-8 mx-6">
                                <button type='submit' className=" text-sm font-semibold leading-none text-white border rounded-2xl hover:bg-blue-600 bg-blue-500  py-2 w-full">
                                    Verify OTP
                                </button>
                            </div>
                        </div>
                    </form>
                    <div className='flex flex-row text-sm mt-8 justify-center align-middle'>
                        <span className='mx-2'>Already have an Account?</span>
                        <Link to='/login'>
                            <span className='text-blue-600 hover:underline cursor-pointer font-semibold'>Login</span>
                        </Link>
                    </div>

                </div>
            </div>
        </React.Fragment>
    )
}

export default SignUp
