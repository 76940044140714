import { all, fork, put, takeEvery } from "redux-saga/effects"
import { GET_ALL_ORDERS, SET_ALL_ORDERS } from "./ordersActionTypes"

function* getAllOrders() {
    const response = yield fetch('https://api.couriero.in/orders/all-orders', {
            method: 'GET',
            headers: {
                'auth-token': localStorage.getItem('token')
            }
        })

        const result = yield response.json()
        yield put({type: SET_ALL_ORDERS, data: result})
}


function* watchGetAllOrders() {
    yield takeEvery(GET_ALL_ORDERS, getAllOrders)
}


function* ordersSaga() {
    yield all([
        fork(watchGetAllOrders)
    ])
}

export default ordersSaga