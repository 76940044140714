import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const TotalShipmentsChart = props => {

    const allOrdersData = useSelector((state) => state.ordersData.allOrders)
    const [allOrders, setAllOrders] = useState([])
    // const series = []

    useEffect(() => {
        setAllOrders(allOrdersData)
    }, [allOrdersData])


    const currentSeries = new Array(12).fill(0); 
    const lastSeries = new Array(12).fill(0)


    allOrders.forEach(({ orderDate }) => {
        if (new Date(orderDate).getFullYear() === new Date().getFullYear()) {
            currentSeries[new Date(orderDate).getMonth()] += 1

        } else if (new Date(orderDate).getFullYear() === new Date().getFullYear()-1) {
            lastSeries[new Date(orderDate).getMonth()] += 1
        }
    });

    const currentYearSum = currentSeries.reduce((a, b) => a + b, 0)

    const series = [
        {
            name: `Year ${new Date().getFullYear()}`,
            data: currentSeries
        },
        {
            name: `Year ${new Date().getFullYear()-1}`,
            data: lastSeries
        },
    ]

    const options = {
        chart: {
            height: 350,
            type: "area",
            toolbar: {
                show: false,
            },
        },
        colors: ["#556ee6", "#f1b44c"],
        dataLabels: {
            enabled: false,
        },
        stroke: {
            curve: "smooth",
            width: 2,
        },
        fill: {
            type: "gradient",
            gradient: {
                shadeIntensity: 1,
                inverseColors: false,
                opacityFrom: 0.45,
                opacityTo: 0.05,
                stops: [20, 100, 100, 100],
            },
        },
        xaxis: {
            categories: [
                "Jan",
                "Feb",
                "Mar",
                "Apr",
                "May",
                "Jun",
                "Jul",
                "Aug",
                "Sep",
                "Oct",
                "Nov",
                "Dec",
            ],
        },

        markers: {
            size: 3,
            strokeWidth: 3,

            hover: {
                size: 4,
                sizeOffset: 2,
            },
        },
        legend: {
            position: "top",
            horizontalAlign: "right",
        },
    }

    return (
        <React.Fragment>
            <div className="flex flex-col">
                <div className="flex flex-row p-2 justify-between">
                    <h5 className="text-lg font-semibold text-left ">Total Shipments</h5>
                    <div className="flex flex-row justify-evenly text-xs">
                        {/* <button className="cursor-pointer mx-1 px-1 rounded inline-block font-medium text-center bg-gray-300">All</button>
                        <button className="cursor-pointer mx-1 px-1 rounded inline-block font-medium text-center bg-gray-300">1 M</button>
                        <button className="cursor-pointer mx-1 px-1 rounded inline-block font-medium text-center bg-gray-300">6 M</button>
                        <button className="cursor-pointer mx-1 px-1 rounded inline-block font-medium text-center bg-gray-300">1 Y</button> */}
                        <h3>Last 2 years</h3>
                    </div>
                </div>
                <div className="flex flex-row justify-evenly w-full text-center border-b-2 pb-2 my-4">
                    <div>
                        <p className="text-xs font font-medium">Last Month</p>
                        <h5 className="text-lg font-medium">{currentSeries[new Date().getMonth()-1]}</h5>
                    </div>
                    <div>
                        <p className="text-xs font font-medium">This Month</p>
                        <h5 className="text-lg font-medium">{currentSeries[new Date().getMonth()]}</h5>
                    </div>
                    <div>
                        <p className="text-xs font font-medium">This Year(Till Now)</p>
                        <h5 className="text-lg font-medium">{currentYearSum}</h5>
                    </div>
                </div>
                <div className="py-4">
                    <ReactApexChart
                        options={options}
                        series={series}
                        type="area"
                        height={250}
                        className="h-3"
                    />
                </div>
            </div>
        </React.Fragment>
    );
};



export default TotalShipmentsChart;
