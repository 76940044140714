import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";

const WeightAnalytics = props => {
     

    const allOrdersData = useSelector((state) => state.ordersData.allOrders)
    const [allOrders, setAllOrders] = useState([])
    const series = []

    useEffect(() => {
        setAllOrders(allOrdersData)
    }, [allOrdersData])


    const lessThan1Kg = allOrders.reduce((accumulator, obj) => {
        if (obj.shipmentChargedWeight < 1) {
          return accumulator + 1;
        }
        return accumulator;
      }, 0);

    const moreThan5Kg = allOrders.reduce((accumulator, obj) => {
        if (obj.shipmentChargedWeight > 5) {
          return accumulator + 1;
        }
        return accumulator;
      }, 0);

    const oneTo5Kg = allOrders.length - lessThan1Kg - moreThan5Kg

    series.push(lessThan1Kg)
    series.push(oneTo5Kg)
    series.push(moreThan5Kg)
      
    const options = {
        labels: ["0-1 Kg", "1-5 Kg", "5 Kg Above"],
        colors: ["#fdba74", "#60a5fa", "#f87171"],
        legend: { show: !1 },
        plotOptions: {
            pie: {
                donut: {
                    size: "70%",
                },
            },
        },
    };



    return (
        <React.Fragment>
            <div className="w-full p-5">
                <div className="flex flex-col">
                    <div className="text-left">
                        <h3 className="text-lg font-semibold">Weight Analytics</h3>
                    </div>
                    <div className="my-2">
                        <ReactApexChart
                            options={options}
                            series={series}
                            type="donut"
                            height={230}
                            className="apex-charts"
                        />
                    </div>
                    <div className="">
                        <div className="flex flex-row justify-evenly">
                            <div className="align-middle text-center">
                                <p className="text-sm font-medium">
                                    <i className="fa-solid fa-circle mr-2" style={{color: '#fdba74'}}></i>
                                    0-1 Kg
                                </p>
                                <h5 className="text-sm font-semibold mt-2">{lessThan1Kg}</h5>
                            </div>
                            <div className="align-middle text-center">
                                <p className="text-sm font-medium">
                                    <i className="fa-solid fa-circle mr-2" style={{color: '#60a5fa'}}></i>
                                    1-5 Kg
                                </p>
                                <h5 className="text-sm font-semibold mt-2">{oneTo5Kg}</h5>
                            </div>
                            <div className="align-middle text-center">
                                <p className="text-sm font-medium">
                                    <i className="fa-solid fa-circle mr-2" style={{color: '#f87171'}}></i>
                                    5 Kg Above
                                </p>
                                <h5 className="text-sm font-semibold mt-2">{moreThan5Kg}</h5>
                            </div>
                        </div>
                    </div>
                </div>

            </div>





        </React.Fragment>
    );
};

export default WeightAnalytics;
