import { configureStore } from '@reduxjs/toolkit'
import rootReducer from "./rootReducer";
import createSagaMiddleware from '@redux-saga/core';
import rootSaga from './rootSaga';



const sagaMiddleware = createSagaMiddleware()

const store = configureStore(
    {
        reducer: rootReducer,
        middleware: () => [sagaMiddleware]
    },
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

sagaMiddleware.run(rootSaga)



export default store

