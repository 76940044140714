import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../components/common/Breadcrumb'
import { useSelector, useDispatch } from 'react-redux';
import { getAllReconciliation } from '../../Store/reconciliation/reconciliationActions'
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';





const WeightReconciliation = () => {
    const dispatch = useDispatch()

    const reconciliationData = useSelector((state) => state.reconciliationData)
    const [data, setData] = useState([])


    useEffect(() => {
        dispatch(getAllReconciliation())
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setData(reconciliationData)
    }, [reconciliationData])




    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 20

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = Array.isArray(data) ? data.slice(itemOffset, endOffset) : [];
    const pageCount = Array.isArray(data) ? Math.ceil(data.length / itemsPerPage) : 0;

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % data.length;
        setItemOffset(newOffset);
    };






    return (
        <React.Fragment>
            <div className=' bg-white mb-12 w-full flex flex-col text-gray-600 overflow-y-auto'>
                <div className='w-full'>
                    <Breadcrumb breadcrumbItem="Weight Reconciliation" title="Billing" />
                </div>

                <div className='w-full md:my-4 md:px-4 rounded-2xl md:pb-5'>
                    <table className='table-fixed py-2 rounded-2xl shadow-2xl md:w-full'>
                        <thead className=' bg-blue-100 text-gray-700 rounded-2xl'>
                            <tr className=''>
                                <th className='py-1 text-sm font-bold w-1/12 border-2'>Date</th>
                                <th className='py-1 text-sm font-bold w-1/12 border-2'>Transaction Id</th>
                                <th className='py-1 text-sm font-bold w-2/12 border-2'>Shipment Details</th>
                                <th className='py-1 text-sm font-bold w-2/12 border-2'>Weight & Dimensions</th>
                                <th className='py-1 text-sm font-bold w-1/12 border-2'>Applied Weight</th>
                                <th className='py-1 text-sm font-bold w-2/12 border-2'>Shipping Charges</th>
                                <th className='py-1 text-sm font-bold w-2/12 border-2'>Wallet Transactions</th>
                                <th className='py-1 text-sm font-bold w-1/12 border-2'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((element) => {
                                return (
                                    <tr key={element._id}>
                                        <td className='text-xs px-1 font-semibold border text-center'>{element.date.split("T")[0]}</td>
                                        <td className='text-xs px-1 font-semibold border text-center'>{element.transactionId}</td>
                                        <td className='text-xs items-center px-1 border py-4' >
                                            <div className='flex flex-col items-center align-middle'>
                                                <div className=''>
                                                    <img src={element.logo} className="w-20 h-3 " alt={element.companyName} />
                                                </div>
                                                <div className='py-1'>
                                                    <p className='font-semibold text-blue-600 text-xs '><span className='font-medium text-xs  '>Awb No. : </span>{element.awbNo}</p>
                                                    <p className='font-semibold text-xs '><span className='font-medium text-xs  '>Order Id : </span>{element.orderId}</p>
                                                    <p className='font-semibold  '><span className='font-medium text-xs'>Courier : </span> {element.companyName}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className='text-xs font-semibold pl-1 border text-left'>
                                            <div>
                                                <p className='text-xs font-semibold'><span className='font-medium text-xs'>Dimension : </span><span>{element.shipmentLength} X {element.shipmentBredth} X {element.shipmentHeight} cm</span></p>
                                                <p className='text-xs font-semibold'><span className='font-medium text-xs'>Weight : </span>{element.shipmentWeight} Kg</p>
                                                <p className='text-xs font-semibold'><span className='font-medium text-xs'>Vol. Weight : </span>{element.shipmentVolWeight} Kg</p>
                                                <p className='text-xs font-semibold'><span className='font-medium text-xs'>Charged Weight : </span><span className='rounded-2xl bg-green-200 px-2'>{element.shipmentChargedWeight} Kg</span></p>
                                            </div>
                                        </td>
                                        <td className='text-xs px-1 font-semibold border text-center'>
                                            <p>{element.appliedWeight} gm </p>
                                            <p>({(element.appliedWeight / 1000).toFixed(2)} Kg)</p>
                                        </td>
                                        <td className='text-xs font-semibold pl-1 border text-left'>
                                            <div>
                                                <p className='text-xs my-2 font-semibold'><span className='font-medium text-xs'>Old Price : </span><span>₹ {element.oldPrice}</span></p>
                                                <p className='text-xs my-2 font-semibold'><span className='font-medium text-xs'>New Price : </span>₹ {element.newPrice}</p>
                                            </div>
                                        </td>
                                        <td className='text-xs font-semibold pl-1 border text-left'>
                                            <div>
                                                <p className='text-xs my-2 font-semibold'><span className='font-medium text-xs'>Refund Txn Id : </span><span>{element.refundWalletTxnId}</span></p>
                                                <p className='text-xs my-2 font-semibold'><span className='font-medium text-xs'>Deduction Txn Id : </span>{element.deductionWalletTxnId}</p>
                                            </div>
                                        </td>
                                        <td className='text-sm text-center border'>
                                            <div className='flex flex-col items-start pl-1'>
                                                <Link
                                                    to='#'
                                                    className='py-1 text-green-500'
                                                >
                                                    <i className="fa-solid fa-eye"></i>
                                                    <button className='text-xs ml-2  bg-green-400 text-white rounded-2xl px-2'>View</button>

                                                </Link>

                                            </div>

                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
                <div className='mb-10 flex items-center flex-col mt-5'>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={
                            <div className='flex flex-row items-center'>
                                <h3 className='mx-2'>Next</h3>
                                <i className="fa-solid fa-arrow-right"></i>

                            </div>
                        }
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        pageCount={pageCount}
                        previousLabel={
                            <div className='flex flex-row items-center'>
                                <i className="fa-solid fa-arrow-left"></i>
                                <h3 className='mx-2'>Prev</h3>
                            </div>
                        }

                        renderOnZeroPageCount={null}
                        containerClassName='flex flex-row items-center'
                        pageClassName='mx-1'
                        pageLinkClassName='px-3 border-blue-500 border rounded-2xl hover:bg-blue-500 hover:text-white'
                        activeLinkClassName='bg-blue-500 text-white'
                        previousClassName='bg-blue-500 cursor-pointer rounded-2xl text-white px-2'
                        nextClassName='bg-blue-500 cursor-pointer rounded-2xl text-white px-2'
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default WeightReconciliation
