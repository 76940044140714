import React, { useState } from 'react'
import Breadcrumb from '../../../components/common/Breadcrumb'
import AuthCode from 'react-auth-code-input';
//import { json } from 'react-router-dom';
//import Papa from 'papaparse';


const Kyc = () => {
    const [selectedType, setSelectedType] = useState('individual');

    const [aadharDetails, setAadharDetails] = useState({ aadharName: '', aadharNumber: '' })
    const [gstDetails, setGstDetails] = useState('')

    const [aadharOtpSection, setAadharOtpSection] = useState('hidden')

    const [aadharOtpResult, setAadharOtpResult] = useState('')
    const [aadharOtpRefId, setAadharOtpRefId] = useState('')


    const [isAadharInputDisabled, setIsAadharInputDisabled] = useState(false)
    const [isGstInputDisabled, setIsGstInputDisabled] = useState(false)


    const handleSelect = (type) => {
        setSelectedType(type)
    }


    const handleAadharDetails = (e) => {
        setAadharDetails({ ...aadharDetails, [e.target.name]: e.target.value })
    }
    const handleGstDetails = (e) => {
        setGstDetails(e.target.value)
    }


    const handleAadharForm = (e) => {
        e.preventDefault()
        sendAadharOtp()
        //setAadharOtpSection('block')
        //setIsAadharInputDisabled(true)
    }

    const sendAadharOtp = async () => {
        const response = await fetch('https://api.couriero.in/kyc/aadhar/otp', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'auth-token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                'aadhar_number': aadharDetails.aadharNumber
            })
        })

        const result = await response.json()

        if (result.status === 'SUCCESS'){
            setAadharOtpSection('block')
            setIsAadharInputDisabled(true)
            setAadharOtpRefId(result.ref_id)
        }

        console.log('aadhar otp result is', result)
    }
    const handleGstForm = (e) => {
        e.preventDefault()
        setIsGstInputDisabled(true)
        verifyGst()
    }

    const verifyGst = async () => {
        const response = await fetch('https://api.couriero.in/kyc/gst', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'auth-token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                'gst_number': gstDetails
            })
        })

        const result = await response.json()
        console.log('gst result is', result)
    }

    const handleAadharOtpChange = (res) => {
        setAadharOtpResult(res)
    }
   

    const handleAadharOtp = (e) => {
        e.preventDefault()
        verifyAadharOtp()
    }

    const verifyAadharOtp = async () => {
        const response = await fetch('https://api.couriero.in/kyc/aadhar/verify', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'auth-token': localStorage.getItem('token')
            },
            body: JSON.stringify({
                'otp': aadharOtpResult,
                'refId': aadharOtpRefId,
                'aadhar_number': aadharDetails.aadharNumber
            })
        })

        const result = await response.json()
        console.log(result)
    }
   



    //fljkdnojnf------------------------------------------------------

    // const [csvData, setCsvData] = useState([]);
    // const [jsonData, setJsonData] = useState(null);

    // const handleFileUpload = async (event) => {
    //     const file = event.target.files[0];
    
    //     try {
    //       const text = await file.text();
    
    //       Papa.parse(text, {
    //         complete: (result) => {
    //           const data = result.data;
    
    //           // Check if there is data and at least one row
    //           if (data && data.length > 0) {
    //             console.log('Parsed CSV Data:', data);
    
    //             // Extract headers from the first row
    //             const headers = Object.keys(data[0]);
    //             console.log('CSV Headers:', headers);
    
    //             // Convert CSV to JSON
    //             const jsonData = data.map(row => {
    //               const rowObject = {};
    //               headers.forEach(header => {
    //                 rowObject[header] = row[header];
    //               });
    //               return rowObject;
    //             });
    
    //             console.log('Converted JSON Data:', jsonData);
    
    //             setCsvData(data);
    //             setJsonData(jsonData);
    
    //             // Uncomment this line to call the API after setting jsonData
    //             // handleApiCall(jsonData);
    //           } else {
    //             console.error('No valid data found in the CSV file.');
    //           }
    //         },
    //         header: true,
    //       });
    //     } catch (error) {
    //       console.error('Error reading CSV file:', error);
    //     }
    //   };

    // const handleApiCall = async () => {

    //     // Make API call with JSON data using fetch
    //     const csv = await fetch('http://localhost:8000/create-shipment/bulk-create', {
    //         method: 'POST',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Auth-Token': "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjp7ImlkIjoiNjNlMTU4NzEzYTM1NTMxZGYyYjAwYzI5In0sImlhdCI6MTcwMjg4NTQzM30.BdpW1o_cVPwm05US-ydRMW7iog9L3ZPvy9E08r7_vOs"
    //         },
    //         body: JSON.stringify(jsonData),
    //     })

    //     const res = await csv.json()
    //     console.log(res)
    // };





    return (
        <React.Fragment>
            <div className='overflow-y-auto mb-12 w-full'>
                <div className='w-full'>
                    <Breadcrumb breadcrumbItem="KYC" title="Settings" />
                </div>
                <div className='text-gray-500 mt-8 mx-4 flex flex-col border-2 shadow-2xl bg-white rounded-2xl p-4'>
                    <h2 className='text-base font-semibold'>State Type Of Your Business</h2>
                    <div className='flex flex-row items-center my-4'>
                        <button
                            className={`border-2 font-medium rounded-3xl px-4 mx-4 bg-${selectedType === 'individual' ? 'blue-600' : 'white'} text-${selectedType === 'individual' ? 'white' : 'blue-600'}`}
                            onClick={() => handleSelect('individual')}
                        >
                            Individual
                        </button>
                        <button
                            className={`border-2 font-medium rounded-3xl px-4 mx-4 bg-${selectedType === 'company' ? 'blue-600' : 'white'} text-${selectedType === 'company' ? 'white' : 'blue-600'}`}
                            onClick={() => handleSelect('company')}
                        >
                            Company
                        </button>
                    </div>
                    <div className={`${selectedType === 'individual' ? 'block' : 'hidden'} rounded-2xl w-2/4 mx-4 border-2 flex flex-col`}>
                        <div className='my-2 mx-2 border-b-2 px-2'>
                            Aadhar Verification
                        </div>
                        <form onSubmit={handleAadharForm}>
                            <div className='flex flex-col my-2 mx-6'>
                                <label htmlFor='aadharName' className='text-sm my-2 mx-2'>Enter Name (as per Aadhar): </label>
                                <input
                                    type="text"
                                    id='aadharName'
                                    name='aadharName'
                                    className='border-2 border-blue-600 rounded-3xl px-2 text-sm w-1/2'
                                    placeholder='Enter Name'
                                    value={aadharDetails.name}
                                    onChange={handleAadharDetails}
                                    required
                                    disabled={isAadharInputDisabled}
                                />
                            </div>
                            <div className='flex flex-col my-2 mx-6'>
                                <label htmlFor='aadharNumber' className='text-sm my-2 mx-2'>Enter Aadhar Number: </label>
                                <input
                                    type='text'
                                    pattern='[0-9]{12}'
                                    id='aadharNumber'
                                    name='aadharNumber'
                                    maxLength={12}
                                    placeholder='Enter Aadhar Number'
                                    className='border-2 border-blue-600 rounded-3xl px-2 text-sm w-1/2'
                                    value={aadharDetails.aadharNumber}
                                    onChange={handleAadharDetails}
                                    required
                                    disabled={isAadharInputDisabled}
                                />
                            </div>
                            <button
                                className={`border bg-blue-600 rounded-3xl text-white text-xs px-4 py-1 mx-6 my-4 font-medium ${aadharOtpSection === 'hidden' ? 'block' : 'hidden'}`}
                                type='submit'
                            >
                                Send OTP
                            </button>
                        </form>

                        <div className={`${aadharOtpSection} mx-6 my-4`}>
                            <form onSubmit={handleAadharOtp}>
                                <div>
                                    <h4 className='mb-4 '>Enter OTP</h4>
                                    <AuthCode
                                        onChange={handleAadharOtpChange}
                                        inputClassName={'border border-solid mx-1 py-1 w-12 border-gray-300 text-center rounded-xl'}
                                        allowedCharacters={'numeric'}
                                    />
                                </div>
                                <button
                                    className='border bg-blue-600 rounded-3xl text-white text-xs px-4 py-1 my-4 font-medium'
                                    type='submit'
                                >
                                    Submit OTP
                                </button>

                            </form>

                        </div>


                    </div>
                    <div className={`${selectedType === 'company' ? 'block' : 'hidden'} rounded-2xl w-2/4 mx-4 border-2 flex flex-col`}>
                        <div className='my-2 mx-2 border-b-2 px-2'>
                            GSTIN Verification
                        </div>
                        <form onSubmit={handleGstForm}>
                            <div className='flex flex-col my-2 mx-6'>
                                <label htmlFor='gstNumber' className='text-sm my-2 mx-2'>Enter GST Number: </label>
                                <input
                                    type="text"
                                    id='gstNumber'
                                    name='gstNumber'
                                    maxLength={15}
                                    className='border-2 border-blue-600 rounded-3xl px-2 text-sm w-1/2'
                                    placeholder='Enter GST Number'
                                    value={gstDetails}
                                    onChange={handleGstDetails}
                                    required
                                    disabled={isGstInputDisabled}
                                />
                            </div>
                            {/* <div className='flex flex-col my-2 mx-6'>
                                <label htmlFor='aadharNumber' className='text-sm my-2 mx-2'>Enter Aadhar Number: </label>
                                <input
                                    type='text'
                                    pattern='[0-9]{12}'
                                    id='aadharNumber'
                                    name='aadharNumber'
                                    maxLength={12}
                                    placeholder='Enter Aadhar Number'
                                    className='border-2 border-blue-600 rounded-3xl px-2 text-sm w-1/2'
                                    value={aadharDetails.aadharNumber}
                                    onChange={handleAadharDetails}
                                    required
                                />
                            </div> */}
                            <button
                                className='border bg-blue-600 rounded-3xl text-white text-xs px-4 py-1 mx-6 my-4 font-medium'
                                type='submit'
                            >
                                Verify GST
                            </button>
                        </form>
                    </div>
                </div>

                {/* <div className='m-8'>
                    <h2>create bulk shipments</h2>

                    <div>
                        <input type="file" onChange={handleFileUpload} accept=".csv" />
                            <div>
                                <div>
                                    <h3>CSV Data:</h3>
                                    <pre>{JSON.stringify(csvData, null, 2)}</pre>
                                </div>
                                <div>
                                    <h3>JSON Data:</h3>
                                    <pre>{JSON.stringify(jsonData, null, 2)}</pre>
                                </div>
                            </div>

                        <div className='m-4'>

                            <button className='rounded-2xl border-2 bg-blue-400 text-white px-2' onClick={handleApiCall}>Send to API</button>
                        </div>

                    </div>
                </div> */}
            </div>
        </React.Fragment>
    )
}

export default Kyc
