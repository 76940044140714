import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { useDispatch, useSelector } from 'react-redux'
import Breadcrumb from '../../components/common/Breadcrumb'
import { getCodRemittance } from '../../Store/codRemittance/codActions'

const CodRemittance = () => {
    const dispatch = useDispatch()

    const [codData, setCodData] = useState([])
    const [itemOffset, setItemOffset] = useState(0);


    const codremittanceData = useSelector((state) => state.codremittanceData)


    useEffect(() => {
        dispatch(getCodRemittance());
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        setCodData(codremittanceData)
    }, [codremittanceData])

    const itemsPerPage = 10
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = codData.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(codData.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % codData.length;
        setItemOffset(newOffset);
    };

    const pendingCod = codData.reduce((accumulator, obj) => {
        if (obj.remittanceStatus === 'Pending') {
            return accumulator + obj.shipments.reduce((a, b) => a + b.amount, 0)
        }
        return accumulator
    }, 0)

    const totalRemittedCod = codData.reduce((accumulator, obj) => {
        if (obj.remittanceStatus === 'Remitted') {
            return accumulator + obj.shipments.reduce((a, b) => a + b.amount, 0)
        }
        return accumulator
    }, 0)

    const lastRemitObj = []
    codData.filter((element) => {
        if (element.remittanceStatus === 'Remitted') {
            lastRemitObj.push(element)
        }
        return lastRemitObj
    })


    const lastRemitAmount = (lastRemitObj[0] === undefined) ? 0 : lastRemitObj[0].amountRemitted


    const badgeColor = (input) => {
        switch (input) {
            case "Pending":
                return "red";

            case "Remitted":
                return "green";

            default: return "green"
        }
    };

    return (
        <React.Fragment>
            <div className='flex flex-col mb-12 w-full overflow-y-auto text-gray-600'>
                <div className='w-full'>
                    <Breadcrumb breadcrumbItem="Cod Remittance" title="Billing" />
                </div>

                    <div className='flex md:flex-row flex-col w-full md:mb-10 md:px-12 md:py-4'>
                        <div className='rounded-2xl shadow-2xl md:w-1/3 w-full bg-white md:mx-3 my-2'>
                            <div className='flex flex-row p-4 justify-between align-middle'>
                                <div className='mt-2'>
                                    <p className='text-sm font-medium'>Total COD Remitted</p>
                                    <h5 className='text-xl font-semibold'>₹ {totalRemittedCod}</h5>
                                </div>
                                <div className='rounded-full bg-blue-200 w-16 h-16 text-center'>
                                    <i className={`fa-solid fa-indian-rupee-sign text-blue-600 text-3xl pt-3`}></i>
                                </div>
                            </div>
                        </div>
                        <div className='rounded-2xl shadow-2xl md:w-1/3 w-full bg-white md:mx-3 my-2'>
                            <div className='flex flex-row p-4 justify-between align-middle'>
                                <div className='mt-2'>
                                    <p className='text-sm font-medium'>Pending COD</p>
                                    <h5 className='text-xl font-semibold'>₹ {pendingCod}</h5>
                                </div>
                                <div className='rounded-full bg-blue-200 w-16 h-16 text-center'>
                                    <i className={`fa-solid fa-indian-rupee-sign text-blue-600 text-3xl pt-3`}></i>
                                </div>
                            </div>
                        </div>
                        <div className='rounded-2xl shadow-2xl md:w-1/3 w-full bg-white md:mx-3 my-2'>
                            <div className='flex flex-row p-4 justify-between align-middle'>
                                <div className='mt-2'>
                                    <p className='text-sm font-medium'>Last COD Remit</p>
                                    <h5 className='text-xl font-semibold'>₹ {lastRemitAmount} </h5>
                                </div>
                                <div className='rounded-full bg-blue-200 w-16 h-16 text-center'>
                                    <i className={`fa-solid fa-indian-rupee-sign text-blue-600 text-3xl pt-3`}></i>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='w-full md:px-4 rounded-2xl py-5 overflow-y-auto'>
                        <table className='table-fixed py-2 my-2 rounded-2xl shadow-2xl md:w-full'>
                            <thead className=' bg-blue-100 text-gray-700 rounded-2xl'>
                                <tr className=''>
                                    <th className='px-6 py-2 text-sm font-bold w-1/5 border-2'>Remittance Id</th>
                                    <th className='px-2 py-2 text-sm font-bold w-1/5 border-2'>Remittance Date</th>
                                    <th className='px-4 py-2 text-sm font-bold w-1/5 border-2'>Transaction Id</th>
                                    <th className='px-4 py-2 text-sm font-bold w-1/5 border-2'>Status</th>
                                    <th className='px-4 py-2 text-sm font-bold w-1/5 border-2'>Remittance Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {currentItems.map((element) => {
                                    return (
                                        <tr key={element._id}>
                                            <td className='text-sm border font-medium text-center py-2 px-4 w-1/5 hover:underline hover:text-blue-600 cursor-pointer'>{element._id}</td>
                                            <td className='text-sm border font-medium text-center py-2 px-4 w-1/5'>{element.remittanceDate.split('T')[0]}</td>
                                            <td className='text-sm border font-medium text-center py-2 px-4 w-1/5'>{element.transactionId ? element.transactionId : '-'}</td>
                                            <td className='text-sm border font-medium text-center py-2 px-4 w-1/5'>
                                                <div className=''>
                                                    <h3 className={`bg-${badgeColor(element.remittanceStatus)}-200 rounded-2xl py-1 mx-10`}>{element.remittanceStatus}</h3>
                                                </div>
                                            </td>
                                            <td className='text-sm border font-medium text-center py-2 px-4 w-1/5'>{element.shipments.reduce((a, b) => a + b.amount, 0)}</td>
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </table>
                    </div>
                    <div className='mb-10 flex items-center flex-col mt-5'>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel={
                                <div className='flex flex-row items-center'>
                                    <h3 className='mx-2'>Next</h3>
                                    <i className="fa-solid fa-arrow-right"></i>

                                </div>
                            }
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={3}
                            pageCount={pageCount}
                            previousLabel={
                                <div className='flex flex-row items-center'>
                                    <i className="fa-solid fa-arrow-left"></i>
                                    <h3 className='mx-2'>Prev</h3>
                                </div>
                            }

                            renderOnZeroPageCount={null}
                            containerClassName='flex flex-row items-center'
                            pageClassName='mx-1'
                            pageLinkClassName='px-3 border-blue-500 border rounded-2xl hover:bg-blue-500 hover:text-white'
                            activeLinkClassName='bg-blue-500 text-white'
                            previousClassName='bg-blue-500 cursor-pointer rounded-2xl text-white px-2'
                            nextClassName='bg-blue-500 cursor-pointer rounded-2xl text-white px-2'
                        />
                    </div>
                </div>
        </React.Fragment>
    )
}

export default CodRemittance
