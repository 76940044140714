import React from 'react'
import Breadcrumb from '../../../components/common/Breadcrumb'
import { Link } from 'react-router-dom'
import shopifyLogo from '../../../Assets/Shopifylogo.png'
import amazonLogo from '../../../Assets/Amazon_logo.png'



const ChannelList = () => {
    return (
        <React.Fragment>
            <div className='bg-white mb-12 w-full flex flex-col text-gray-600 overflow-y-auto'>
                <div className='w-full'>
                    <Breadcrumb breadcrumbItem="Add Channels" title="Channels" />
                </div>

                <div className='flex flex-col'>
                    <div className='flex flex-row mx-4 w-full my-4'>
                        <div className='flex flex-col border-2 rounded-2xl m-4 w-1/4 h-40 justify-evenly text-center items-center' >
                            <div className='w-full'>
                                <p className='font-bold text-sm'>SHOPIFY</p>
                            </div>
                            <div className='w-full items-center align-middle flex flex-col my-4'>
                                <img src={shopifyLogo} alt='shopify' className='w-30 h-8' />
                            </div>
                            <div className='w-full'>
                                <Link
                                    to='/channels/add/shopify'
                                    className='py-1 text-blue-500'
                                >
                                    <button className='border rounded-2xl px-6 py-1 bg-blue-400 text-white text-xs font-medium'>
                                        Add
                                    </button>
                                </Link>
                            </div>
                        </div>

                        <div className='flex flex-col border-2 rounded-2xl m-4 w-1/4 h-40 justify-evenly text-center items-center'>
                            <div className='w-full'>
                                <p className='font-bold text-sm'>AMAZON</p>
                            </div>
                            <div className='w-full items-center align-middle flex flex-col my-4'>
                                <img src={amazonLogo} alt='shopify' className='w-30 h-8' />
                                <p className='text-xs items-center'>(Comming Soon)</p>
                            </div>
                            <div className='w-full'>
                                <button className='border rounded-2xl px-6 py-1 bg-blue-400 text-white text-xs font-medium'>
                                    Add
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </React.Fragment>
    )
}

export default ChannelList
