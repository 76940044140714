import React, { useEffect, useState } from 'react'
import Breadcrumb from '../../components/common/Breadcrumb'

import dashwelcome from '../../Assets/dashboard-welcome.png'
import WeightAnalytics from './WeightAnalytics'
import TodayCard from './TodayCard'
import TotalShipmentsChart from './TotalShipments'
import ShipmentTypeAnalytics from './ShipmentTypeAnalytics'
import { useSelector } from 'react-redux'
import avatar from '../../Assets/profile-pic.png'
import { Link } from 'react-router-dom'


const Dashboard = () => {

  const allOrdersData = useSelector((state) => state.ordersData.allOrders)
  const userDetails = useSelector((state) => state.userDetails)
  const codremittanceData = useSelector((state) => state.codremittanceData)


  const [allOrders, setAllOrders] = useState([])
  const [codData, setCodData] = useState([])


  useEffect(() => {
    setAllOrders(allOrdersData)
  }, [allOrdersData])

  useEffect(() => {
    setCodData(codremittanceData)
  }, [codremittanceData])




  console.log('all orders are ', allOrdersData)

  const todayOrder = allOrders.reduce((accumulator, obj) => {
    const todayDate = new Date()
    const todayDay = `${todayDate.getDate()}-${todayDate.getMonth()}-${todayDate.getFullYear()}`
    const orderDate = new Date(obj.orderDate)
    const orderDay = `${orderDate.getDate()}-${orderDate.getMonth()}-${orderDate.getFullYear()}`

    if (todayDay === orderDay) {
      return accumulator + 1
    }
    return accumulator
  }, 0)

  const todayRevenue = allOrders.reduce((accumulator, obj) => {
    const todayDate = new Date()
    const todayDay = `${todayDate.getDate()}-${todayDate.getMonth()}-${todayDate.getFullYear()}`
    const orderDate = new Date(obj.orderDate)
    const orderDay = `${orderDate.getDate()}-${orderDate.getMonth()}-${orderDate.getFullYear()}`

    if (todayDay === orderDay) {
      return accumulator + obj.productAmount
    }
    return accumulator
  }, 0)

  const totalShippingCost = allOrders.reduce((accumulator, obj) => {
    if (obj.price === undefined) {
      return accumulator + 50
    } else {
      return accumulator + obj.price
    }
  }, 0)

  const avgShippingCost = (totalShippingCost / allOrders.length).toFixed(2)

  const inTransitShipments = allOrders.reduce((accumulator, obj) => {
    if (obj.shipmentStatus === 'In Transit') {
      return accumulator + 1
    }
    return accumulator
  }, 0)

  const deliveredShipments = allOrders.reduce((accumulator, obj) => {
    if (obj.shipmentStatus === 'Delivered') {
      return accumulator + 1
    }
    return accumulator
  }, 0)

  const rtoShipments = allOrders.reduce((accumulator, obj) => {
    if (obj.shipmentStatus === 'RTO') {
      return accumulator + 1
    }
    return accumulator
  }, 0)


  const pendingCod = codData.reduce((accumulator, obj) => {
    if (obj.remittanceStatus === 'Pending') {
      return accumulator + obj.shipments.reduce((a, b) => a + b.amount, 0)
    }
    return accumulator
  }, 0)

  const totalRemittedCod = codData.reduce((accumulator, obj) => {
    if (obj.remittanceStatus === 'Remitted') {
      return accumulator + obj.shipments.reduce((a, b) => a + b.amount, 0)
    }
    return accumulator
  }, 0)

  const lastRemitObj = []
  codData.filter((element) => {
    if (element.remittanceStatus === 'Remitted') {
      lastRemitObj.push(element)
    }
    return lastRemitObj
  })


  const lastRemitAmount = (lastRemitObj[0] === undefined) ? 0 : lastRemitObj[0].amountRemitted





  const capitalizeFirstWord = (input) => {
    if (typeof input !== 'undefined') {
      var word = input.trim().replace(/[\s\t]+/g, ' ');
      return word.split(' ').map(word => word[0].toUpperCase() + word.slice(1)).join(' ');
    };
  }



  return (
    <React.Fragment>
      <div className='flex flex-col text-gray-600 mb-12 w-full '>
        <div>
          <Breadcrumb breadcrumbItem="Dashboard" title="Home" />
        </div>
        <div className='flex md:flex-row flex-col w-full '>
          <div className='md:w-1/3 flex flex-col w-full'>
            {/* Profile Tab */}
            <div className='flex flex-col shadow-2xl rounded-2xl border border-gray-200 my-2 w-full'>
              <div className='bg-blue-200 rounded-t-2xl'>
                <div className='flex flex-row'>
                  <div className='w-2/3'>
                    <div className='ml-4 mt-4 flex flex-col'>
                      <h5 className='font-medium text-sm text-blue-600'>Welcome Back !</h5>
                      <p className='font-normal text-sm text-blue-600'>Couriero Dashboard</p>
                    </div>
                  </div>
                  <div className='w-1/3'>
                    <img src={dashwelcome} alt='dashwelcome' />
                  </div>
                </div>
              </div>
              <div className='px-2 bg-white pb-4 rounded-b-2xl flex flex-row justify-around items-center'>
                <div className='flex flex-col items-center justify-between'>
                  <div className='mb-2 md:-mt-6 mt-2 mx-2'>
                    <img src={userDetails.profilePhoto ? userDetails.profilePhoto : avatar} className='rounded-full md:h-16 md:w-16 h-8 w-8' alt='avatar' />
                  </div>
                  <div className='mx-2'>
                    <h5 className='font-medium first-letter: text-sm text-gray-600'>{capitalizeFirstWord(userDetails.name)}</h5>
                    <p className='font-normal text-xs text-gray-600'>{capitalizeFirstWord(userDetails.companyName)}</p>
                  </div>
                </div>
                <div className='flex justify-center'>
                  <div className=' text-center mt-2'>
                    <Link to='/profile'>
                    <button className='bg-blue-500 rounded-2xl px-2 text-white text-sm '>
                      View Profile
                    </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            {/* Profile tab end */}

            <div className='bg-white rounded-2xl border border-gray-200 shadow-2xl my-2 w-full'>
              <WeightAnalytics />
            </div>

            <div className='bg-white rounded-2xl shadow-2xl border border-gray-200 w-full my-2'>
              <ShipmentTypeAnalytics />
            </div>
          </div>

          <div className='md:w-2/3 md:mx-4 flex flex-col w-full'>

            <div className='flex md:flex-row flex-wrap md:flex-nowrap'>
              <div className='rounded-2xl shadow-2xl md:w-1/3 w-full bg-white border border-gray-200  my-2'>
                <TodayCard title="Today's Order" amount={todayOrder} lable='clipboard-list' />
              </div>
              <div className='rounded-2xl shadow-2xl md:w-1/3 w-full bg-white border border-gray-200 md:mx-2 my-2'>
                <TodayCard title="Today's Revenue" amount={`₹ ${todayRevenue}`} lable='indian-rupee-sign' />
              </div>
              <div className='rounded-2xl shadow-2xl md:w-1/3 w-full bg-white border border-gray-200  my-2'>
                <TodayCard title='Avg Shipping Cost' amount={`₹ ${avgShippingCost}`} lable='ticket' />
              </div>
            </div>

            <div className='rounded-2xl shadow-2xl bg-white my-2 w-full'>
              <TotalShipmentsChart />
            </div>

            <div className='flex flex-col w-full'>

              <div className='rounded-2xl shadow-2xl bg-white my-2 py-2 w-full'>
                <div className='flex flex-col'>
                  <div className='flex flex-row justify-between py-2 px-2'>
                    <h3 className='text-base  font-semibold'>Shipment Details</h3>
                    {/* <p className='font-medium text-xs'>Last 30 days</p> */}
                  </div>
                  <div className='md:flex md:flex-row grid grid-rows-2 gap-2 grid-flow-col justify-evenly text-center items-center'>
                    <div className='px-2'>
                      <span className='md:text-2xl text-base font-medium'>{allOrders.length}</span>
                      <h5 className='mt-2 text-xs font-semibold'>Total Shipments</h5>
                    </div>
                    <div className='px-2'>
                      <span className='md:text-2xl text-base font-medium'>{inTransitShipments}</span>
                      <h5 className='mt-2 text-xs font-semibold'>In-Transit</h5>
                    </div>
                    <div className='px-2'>
                      <span className='md:text-2xl text-base font-medium'>{deliveredShipments}</span>
                      <h5 className='mt-2 text-xs font-semibold'>Delivered</h5>
                    </div>
                    <div className='px-2'>
                      <span className='md:text-2xl text-base font-medium'>{rtoShipments}</span>
                      <h5 className='mt-2 text-xs font-semibold'>RTO Shipments</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className='rounded-2xl shadow-2xl bg-white my-2 py-2 w-full'>
                <div className='flex flex-col'>
                  <div className='flex flex-row justify-between py-2 px-2'>
                    <h3 className='text-base font-semibold'>COD Details</h3>
                    {/* <p className='font-medium text-xs'>Last 30 days</p> */}
                  </div>
                  <div className='md:flex md:flex-row grid grid-rows-2 gap-2 grid-flow-col justify-evenly text-center items-center '>
                    <div>
                      <span className='md:text-2xl text-base font-medium'>₹ {totalRemittedCod}</span>
                      <h5 className='mt-2 text-xs font-semibold'>Total COD Remitted</h5>
                    </div>
                    <div>
                      <span className='md:text-2xl text-base font-medium'>₹ {pendingCod}</span>
                      <h5 className='mt-2 text-xs font-semibold'>Pending COD</h5>
                    </div>
                    <div>
                      <span className='md:text-2xl text-base font-medium'>₹ {lastRemitAmount}</span>
                      <h5 className='mt-2 text-xs font-semibold'>Last Remit</h5>
                    </div>
                  </div>
                </div>
              </div>

              <div className='rounded-2xl shadow-2xl bg-white my-2 py-2 w-full transition ease-in-out hover:-translate-y-1 hover:scale-110 hover:bg-orange-200 duration-300'>
                <div className='flex flex-col'>
                  <div className='flex flex-row justify-between py-2 px-2'>
                    <h3 className='text-base font-semibold'>RTO Details</h3>
                    {/* <p className='font-medium text-xs'>Last 30 days</p> */}
                  </div>
                  <div className='md:flex md:flex-row grid grid-rows-2 gap-2 grid-flow-col justify-evenly text-center items-center'>
                    <div>
                      <span className='md:text-2xl text-base font-medium'>{rtoShipments}</span>
                      <h5 className='mt-2 text-xs font-semibold'>Total RTO Shipments</h5>
                    </div>
                    <div>
                      <span className='md:text-2xl text-base font-medium'>{rtoShipments}</span>
                      <h5 className='mt-2 text-xs font-semibold'>RTO In-Transit</h5>
                    </div>
                    <div>
                      <span className='md:text-2xl text-base font-medium'>{rtoShipments}</span>
                      <h5 className='mt-2 text-xs font-semibold'>RTO Delivered.</h5>
                    </div>
                    {/* <div>
                        <span className='rounded-2xl bg-green-200 text-lg font-medium  px-2 py-2'>110</span>
                        <h5 className='mt-3 text-sm font-medium'>RTO Shipments</h5>
                      </div> */}
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </React.Fragment>
  )
}

export default Dashboard
