import { SET_ALL_ORDERS } from "./ordersActionTypes"

const ordersDataState = {
    allOrders: [],
}

const ordersData = (state = ordersDataState, action) => {
    switch (action.type) {
        case SET_ALL_ORDERS:
            return {...state, allOrders : [...action.data]};
        default: return state
    }
}

export default ordersData

