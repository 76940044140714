import { takeEvery, put, all, fork} from 'redux-saga/effects'
import { ADD_CREDIT_WALLET_TRANSACTION, ADD_WALLET_TRANSACTION, GET_ALL_WALLET_TRANSACTION, SET_ALL_WALLET_TRANSACTION } from './actionTypes';

function* getAllWalletTransaction() {
    const response = yield fetch('https://api.couriero.in/wallet/get-all-wallet-transactions', {
        method: 'POST',
        headers: {
            'auth-token': localStorage.getItem('token')

        }
    })

    const result = yield response.json()
    yield put({type: SET_ALL_WALLET_TRANSACTION , data: result})
}

function* addNewWalletTransaction(data) {
  
    const response2 = yield fetch('https://api.couriero.in/wallet/add-wallet-transaction', {
        method: 'POST',
        headers: {
            'auth-token': localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
            amount: data.payload.amount,
            description: data.payload.description,
            type: "Debit"
        })
    })
    const result2 = yield response2.json()
    yield console.log(result2)
}

function* addCreditWalletTransaction(data) {
    const response = yield fetch('https://api.couriero.in/wallet/add-credit-wallet-transaction', {
        method: 'POST',
        headers: {
            'auth-token': localStorage.getItem('token'),
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ 
            amount: data.payload.amount,
            description: data.payload.description,
            type: "Credit"
        })
    })
    const result2 = yield response.json()
    yield console.log(result2)

}

function* watchGetAllWalletTransaction(){
    yield takeEvery(GET_ALL_WALLET_TRANSACTION, getAllWalletTransaction)
}

function* watchAddNewWalletTransaction(){
    yield takeEvery(ADD_WALLET_TRANSACTION, addNewWalletTransaction)
}

function* watchAddCreditWalletTransaction() {
    yield takeEvery(ADD_CREDIT_WALLET_TRANSACTION, addCreditWalletTransaction)
}

function* walletSaga() {
    yield all([
        fork(watchGetAllWalletTransaction),
        fork(watchAddNewWalletTransaction),
        fork(watchAddCreditWalletTransaction)
    ])
}

export default walletSaga;