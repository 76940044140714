import React, { useEffect, useState } from 'react'

import avatar from '../../Assets/profile-pic.png'

import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

const ProfileMenu = () => {
    const [allUserDetails, setAllUserDetails] = useState({})

    const userDetails = useSelector((state) => state.userDetails)

    useEffect(() => {
        setAllUserDetails(userDetails)
    }, [userDetails])


    const navigate = useNavigate()

    const [menu, setMenu] = useState("hidden");
    const onClick = () => {
        if (menu === "hidden") {
            setMenu("block")
        } else {
            setMenu("hidden")
        }
    }

    const handleLogout = () => {
        localStorage.removeItem('token')
        navigate('/login')

    }

    const capitalizeFirstWord = (input) => {
        if (typeof input !== 'undefined') {
            var word = input.trim().replace(/[\s\t]+/g, ' ');
            return word.split(' ').map(word => word[0].toUpperCase() + word.slice(1)).join(' ');
        };
    }



    return (
        <React.Fragment>
            <button
                onClick={onClick}
                className='flex flex-row items-center'
            >
                <img
                    className="rounded-full h-9 w-9 p-1 align-middle "
                    src={allUserDetails.profilePhoto ? allUserDetails.profilePhoto : avatar}
                    alt="Header Avatar"
                />
                <span className="hidden md:block ml-1 text-gray-600 text-sm font-medium ">{capitalizeFirstWord(allUserDetails.name)}</span>
                <i className="fa-solid fa-angle-down ml-1 text-gray-600" />
            </button>
            <div className={`${menu} absolute z-50 w-44 right-0 my-3 shadow md:mr-10 mr-2 bg-white`}>
                <ul className='py-1 text-sm text-gray-700' >
                    <li>
                        <Link to="/profile" className='block py-2 px-2'>
                            <i className='fa-solid fa-user mr-2' />
                            <span>Profile</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/wallet" className='block py-2 px-2'>
                            <i className='fa-solid fa-wallet mr-2' />
                            <span>Wallet</span>
                        </Link>
                    </li>
                    <li>
                        <Link to="/#" className='block py-2 px-2'>
                            <i className='fa-solid fa-gear mr-2' />
                            <span>Settings</span>
                        </Link>
                    </li>
                    <hr />
                    <li>
                        <button to="/logout" onClick={handleLogout} className='block py-2 px-2'>
                            <i className='fa-solid fa-power-off mr-2 text-red-700' />
                            <span>Logout</span>
                        </button>
                    </li>
                </ul>
            </div>
        </React.Fragment>
    )
}

export default ProfileMenu
