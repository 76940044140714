import React from 'react'
import SidebarContent from './SidebarContent'



const Sidebar = () => {
  return (
    <React.Fragment>
      <div className='w-full bg-white h-full overflow-x-hidden overflow-y-hidden'>
        <SidebarContent />
      </div>
    </React.Fragment>

  )
}

export default Sidebar
