import { all, fork } from "redux-saga/effects";
import ordersSaga from "./orders/ordersSaga";
import trackingSaga from "./tracking/trackingSaga";
import userDetailsSaga from "./userDetails/userDetailsSaga";
import walletSaga from "./wallet/walletSaga";
import codRemittanceSaga from "./codRemittance/codSaga"
import channelsSaga from "./channels/channelsSaga";
import reconciliationSaga from "./reconciliation/reconciliationSaga"
import channelOrdersSaga from "./channelOrders/channelOrdersSaga";

export default function* rootSaga() {
    yield all ([
        fork(walletSaga),
        fork(ordersSaga),
        fork(trackingSaga),
        fork(userDetailsSaga),
        fork(codRemittanceSaga),
        fork(channelsSaga),
        fork(reconciliationSaga),
        fork(channelOrdersSaga)
    ])
}