import React from 'react'

const Footer = () => {
  return (
    <React.Fragment>
      <div className='fixed bottom-0 w-full bg-white shadow-2xl z-50 '>
        <div className=' flex flex-row justify-between items-center h-6 md:h-10 md:mx-10 mx-2	'>
            <span className='text-gray-600 md:text-sm text-xs font-semibold text-center' >2022 © Couriero.</span>
            <span className='text-gray-600 text-xs md:text-sm font-semibold text-center hidden md:block'>Design & Develop by Soft-Innov Technologies Pvt. Ltd.</span>
            <span className='text-gray-600 text-xs md:text-sm font-semibold text-center md:hidden'>Soft-Innov Technologies Pvt. Ltd.</span>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Footer
