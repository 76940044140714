import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import Breadcrumb from '../../components/common/Breadcrumb'
import ReactPaginate from 'react-paginate';
import AllOrdersItems from './AllOrdersItems';
import Toast from '../../components/toast/Toast';
import DatePicker from 'react-datepicker';
import * as XLSX from 'xlsx';
import { startOfDay, endOfDay } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { startOfWeek, startOfMonth, endOfMonth, subMonths, subDays , endOfWeek } from 'date-fns';



const AllOrders = () => {
    const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
    const [selectedRange, setSelectedRange] = useState(null);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const predefinedRanges = {
        today: [startOfDay(new Date()), endOfDay(new Date())],
        yesterday: [startOfDay(subDays(new Date(), 1)), endOfDay(subDays(new Date(), 1))],
        thisWeek: [startOfDay(startOfWeek(new Date())), endOfDay(new Date())],
        lastWeek: [startOfDay(startOfWeek(subDays(new Date(), 7))), endOfDay(endOfWeek(subDays(new Date(), 7)))],
        thisMonth: [startOfDay(startOfMonth(new Date())), endOfDay(new Date())],
        lastMonth: [startOfDay(startOfMonth(subMonths(new Date(), 1))),endOfDay( endOfMonth(subMonths(new Date(), 1)))],
        last90Days: [startOfDay(subDays(new Date(), 90)), endOfDay(new Date())],
    };

    const handleRangeClick = (range) => {
        const [start, end] = predefinedRanges[range];
        setSelectedRange(range);
        setStartDate(start);
        setEndDate(end);
    };

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    const handleDivOnClick = (e) => {
        e.preventDefault();
        setIsDatePickerVisible(false)
    }


    const allOrdersData = useSelector((state) => state.ordersData.allOrders)

    const [itemOffset, setItemOffset] = useState(0);

    const itemsPerPage = 20

    const [allOrders, setAllOrders] = useState([])
    const [selectedFilter, setSelectedFilter] = useState('All');
    const [searchData, setSearchData] = useState('')
    const [selectedAwbs, setSelectedAwbs] = useState([]);
    const [masterChecked, setMasterChecked] = useState(false);
    const [toastArgs, setToastArgs] = useState(null)


    const showToast = (message, type) => {
        setToastArgs({
            message,
            type
        })
        setTimeout(() => {
            setToastArgs(null)
        }, 5000);
    }


    useEffect(() => {
        let filteredData = allOrdersData;

        if (searchData.length !== 0) {
            filteredData = filteredData.filter((item) => item.consigneeName.toLowerCase().includes(searchData.toLowerCase()) || item.consigneeMobNo.toString().includes(searchData) || item.orderId.toLowerCase().includes(searchData.toLowerCase()) || item.awbNo.toString().includes(searchData))
        }

        if (selectedFilter !== 'All') {
            filteredData = filteredData.filter(order => selectedFilter.includes(order.shipmentStatus))
        }
        if (startDate && endDate) {
            filteredData = filteredData.filter((item) => {
                const itemDate = new Date(item.orderDate);
                return itemDate >= startOfDay(new Date(startDate)) && itemDate <= endOfDay(new Date(endDate));
            });
        }

        setAllOrders(filteredData);
        setItemOffset(0);
    }, [allOrdersData, searchData, selectedFilter, startDate, endDate]);



    useEffect(() => {
        if (searchData.length !== 0) {
            const filteredData = allOrdersData.filter((item) => item.consigneeName.toLowerCase().includes(searchData.toLowerCase()) || item.consigneeMobNo.toString().includes(searchData) || item.orderId.toLowerCase().includes(searchData.toLowerCase()) || item.awbNo.toString().includes(searchData))
            setAllOrders(filteredData)
            setItemOffset(0);
        } else {
            setAllOrders(allOrdersData)
        }
        // eslint-disable-next-line
    }, [allOrdersData, searchData])

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = allOrders.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(allOrders.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % allOrders.length;
        setItemOffset(newOffset);
        setSelectedAwbs([]);
        setMasterChecked(false);
    };





    const handleFilterChange = (event) => {
        const selectedValue = event.target.value;

        switch (selectedValue) {
            case 'inTransit':
                setSelectedFilter(['In Transit', 'Manifested', 'Not Picked', 'Out For Delivery', 'Delivery Destination']);
                break;
            case 'rto':
                setSelectedFilter(['RTO In Transit', 'RTO Delivery Destination', 'RTO Out For Delivery', 'RTO Delivered']);
                break;
            case 'delivered':
                setSelectedFilter(['Delivered', 'RTO Delivered', 'Return Delivered']);
                break;
            case 'return':
                setSelectedFilter(['Return Manifested', 'Return Scheduled', 'Return Out For Pickup', 'Return In Transit', 'Return Delivery Destination', 'Return Out For Delivery', 'Return Delivered', 'Return Cancelled', 'Return Closed'])
                break;
            default:
                setSelectedFilter('All');
        }
    };

    const handleSearchBox = (e) => {
        setSearchData(e.target.value)
    }

    const handleCheckboxChange = (awbNo, isChecked) => {
        setSelectedAwbs(prevSelectedAwbs =>
            isChecked ? [...prevSelectedAwbs, awbNo] : prevSelectedAwbs.filter(awb => awb !== awbNo)
        );
    };

    const handleDownloadLabels = async () => {
        showToast('Generating Labels, Download may take upto 10 second, Please Wait !!', 'success')
        const response = await fetch('https://api.couriero.in/shipping-label/multiple', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'auth-token': localStorage.getItem('token')
            },
            body: JSON.stringify({ awbNos: selectedAwbs })
        });
        const result = await response.json();
        // if (result.success) {
        //     // Handle successful label generation
        //     console.log('Labels generated successfully');
        // } else {
        //     // Handle errors
        //     console.error('Failed to generate labels');
        // }

        const base64Data = await result.pdf



        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);

        // Create a blob from the byte array
        const blob = new Blob([byteArray], { type: 'application/pdf' });

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = `mergedShippingLabels.pdf`;

        link.click()

    }

    const handleMasterCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        setMasterChecked(isChecked);
        if (isChecked) {
            const allAwbs = currentItems.map(order => order.awbNo);
            setSelectedAwbs(allAwbs);
        } else {
            setSelectedAwbs([]);
        }
    };

    const handleDownloadReport = () => {
        const downloadReport = [
            ['AWB No', 'Order Id', 'Order Date', 'Delivery Date', 'Company Name', 'Consignee Name', 'Consignee Address', 'Consignee PinCode', 'Consignee City', 'Consignee State', 'Consignee MobNo', 'Consignee Email', 'Pickup ContactName', 'Pickup Address', 'Pickup PinCode', 'Pickup City', 'Pickup State', 'Pickup MobNo', 'Product Name', 'Product Amount', 'OrderType', 'Length', 'Bredth', 'Height',
                'Dead Weight', 'Volumetric Weight', 'Charged Weight', 'Status']
        ]

        const handleData = allOrders
        const filteredData = handleData.map((element) => {

            return [
                element.awbNo,
                element.orderId,
                new Date(element.orderDate).toISOString().split('T')[0],
                element.deliveryDate ? new Date(element.deliveryDate).toISOString().split('T')[0] : '',
                element.companyName,
                element.consigneeName,
                element.consigneeAddress,
                element.consigneePinCode,
                element.consigneeCity,
                element.consigneeState,
                element.consigneeMobNo,
                element.consigneeEmail,
                element.pickupAddressContactName,
                element.pickupAddress,
                element.pickupPinCode,
                element.pickupAddressCity,
                element.pickupAddressState,
                element.pickupAddressMobNo,
                element.productName,
                element.productAmount,
                element.productOrderType,
                element.shipmentLength,
                element.shipmentBredth,
                element.shipmentHeight,
                element.shipmentWeight,
                element.shipmentVolWeight,
                element.shipmentChargedWeight,
                element.shipmentStatus,
            ]
        })

        const downloadData = downloadReport.concat(filteredData)

        console.log('download here', downloadData)

        XLSX.writeFile({ SheetNames: ['Orders'], Sheets: { Orders: XLSX.utils.aoa_to_sheet(downloadData) } }, 'orders.xlsx')

    }


    const resetFilter = () => {
        setSelectedRange(false)
        setStartDate(null)
        setEndDate(null)
        setSelectedFilter('All')
        setSearchData('')
    }


    return (
        <React.Fragment>
            <div className='overflow-y-auto pb-44 bg-white mb-12 w-full'>
                <div className='w-full'>
                    <Breadcrumb breadcrumbItem="All Orders" title="Orders" />
                </div>
                {toastArgs !== null && <Toast toastArgs={toastArgs} />}
                <div className='flex w-full justify-center items-center lg:flex-row flex-col my-5 py-2 '>
                    <div className='rounded-3xl flex items-center    md:w-1/3'>
                        <input
                            type="text"
                            className=" h-6 md:pl-5 pl-3 rounded-3xl outline-none focus:outline-none bg-transparent w-full shadow text-xs font-medium text-gray-500	"
                            placeholder="Search AWB/OrderID/Mob No/Name"
                            onChange={handleSearchBox}
                            value={searchData}
                        />
                    </div>
                    <div className='flex md:py-0  lg:py-0 py-4 flow-row md:w-2/3 items-center '>
                        <label className=' md:mx-2 ml-10 mr-2
                         text-sm'>Filters: </label>
                        <div className='flex flex-row gap-2'>
                            <select
                                className='text-xs font-normal border-solid border-2 border-blue-500 rounded-2xl px-4'
                                onChange={handleFilterChange}
                                defaultValue=''

                            >
                                <option value="" disabled>Shipment Status</option>
                                <option value="All">All</option>
                                <option value="inTransit">In Transit</option>
                                <option value="rto">RTO</option>
                                <option value="delivered">Delivered</option>
                                <option value="return">Return</option>
                            </select>
                            <button
                                className="text-xs flex justify-between  items-center font-normal border-solid border-2 border-blue-500 rounded-2xl  px-6"
                                onClick={() => setIsDatePickerVisible(!isDatePickerVisible)}
                            >
                                Filter Dates
                                <i className="fa-solid flex pl-2  fa-caret-down"></i>
                            </button>
                            <button onClick={resetFilter} className='text-xs flex justify-between  items-center font-normal border-solid border-2 border-blue-500 bg-blue-500 text-white shadow-md rounded-2xl  px-6'>
                                Reset filters
                            </button>
                            <button onClick={handleDownloadReport} className='text-xs flex justify-between  items-center font-normal border-solid border-2 border-blue-500 bg-blue-500 text-white shadow-md rounded-2xl  px-3'>
                                Download Report
                                <i className="fa-solid pl-2 fa-download"></i>
                            </button>
                        </div>
                    </div>
                </div>
                {isDatePickerVisible && (
                    <>
                        <div className='fixed  w-full h-full  bg-transparent' onClick={handleDivOnClick}>
                        </div>
                        <div className='flex lg:flex-row md:flex-row flex-col'>
                            <div className='lg:flex md:flex hidden lg:w-1/2'>
                            </div>
                            <div className="relative flex lg:w-1/2">
                                <div className="absolute  w-full flex flex-row ">
                                    <div className="w-1/3 bg-gray-100 p-1">
                                        <button
                                            className={`w-full text-left lg:text-sm text-xs mb-1 lg:px-5 py-1 ${selectedRange === 'today' ? 'bg-blue-900 text-white' : ''}`}
                                            onClick={() => handleRangeClick('today')}
                                        >
                                            Today
                                        </button>
                                        <button
                                            className={`w-full text-left lg:text-sm text-xs mb-1  lg:px-5 py-1 ${selectedRange === 'yesterday' ? 'bg-blue-900 text-white' : ''}`}
                                            onClick={() => handleRangeClick('yesterday')}
                                        >
                                            Yesterday
                                        </button>
                                        <button
                                            className={`w-full text-left lg:text-sm text-xs mb-1  lg:px-5 py-1 ${selectedRange === 'thisWeek' ? 'bg-blue-900 text-white' : ''}`}
                                            onClick={() => handleRangeClick('thisWeek')}
                                        >
                                            This Week
                                        </button>
                                        <button
                                            className={`w-full text-left lg:text-sm text-xs mb-1  lg:px-5 py-1 ${selectedRange === 'lastWeek' ? 'bg-blue-900 text-white' : ''}`}
                                            onClick={() => handleRangeClick('lastWeek')}
                                        >
                                            Last Week
                                        </button>
                                        <button
                                            className={`w-full text-left lg:text-sm text-xs mb-1  lg:px-5 py-1 ${selectedRange === 'thisMonth' ? 'bg-blue-900 text-white' : ''}`}
                                            onClick={() => handleRangeClick('thisMonth')}
                                        >
                                            This Month
                                        </button>
                                        <button
                                            className={`w-full text-left lg:text-sm text-xs mb-1  lg:px-5 py-1 ${selectedRange === 'lastMonth' ? 'bg-blue-900 text-white' : ''}`}
                                            onClick={() => handleRangeClick('lastMonth')}
                                        >
                                            Last Month
                                        </button>
                                        <button
                                            className={`w-full text-left lg:text-sm text-xs mb-1  lg:px-5 py-1 ${selectedRange === 'last90Days' ? 'bg-blue-900 text-white' : ''}`}
                                            onClick={() => handleRangeClick('last90Days')}
                                        >
                                            Last 90 Days
                                        </button>
                                        <button
                                            className={`w-full text-left lg:text-sm text-xs mb-1  lg:px-5 py-1 ${!selectedRange ? 'bg-blue-900 text-white' : ''}`}
                                            onClick={() => setSelectedRange(null)}
                                        >
                                            Custom
                                        </button>
                                    </div>
                                    <div className="flex w-2/3 flex-col ml-3">
                                        <div className="justify-center items-center flex-col">
                                            <DatePicker
                                                selected={startDate}
                                                onChange={handleDateChange}
                                                startDate={startDate}
                                                endDate={endDate}
                                                selectsRange
                                                inline
                                                className="border flex w-full border-gray-300 rounded-lg"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                <div className='flex mb-4 flow-row w-full items-center '>
                    {selectedAwbs.length > 0 && (
                        <button onClick={handleDownloadLabels} className='btn btn-primary rounded-full bg-blue-300 text-blue-600 font-medium px-6 shadow-md   text-sm'>
                            Download All Labels
                        </button>
                    )}
                </div>
                <div className='rounded-2xl'>
                    <table className='table-fixed py-2 my-2  '>
                        <thead className=' bg-blue-100 border border-slate-300 text-gray-700 rounded-2xl'>
                            <tr className='w-full'>
                                <th className='p-2 w-min'>
                                    <div className="flex items-center">
                                        <input id="checkbox-all-search" type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 " checked={masterChecked} onChange={handleMasterCheckboxChange} />
                                        <label htmlFor="checkbox-all-search" className="sr-only">checkbox</label>
                                    </div>
                                </th>
                                <th className=' text-sm font-bold w-1/12'>Order Id</th>
                                <th className=' text-sm font-bold w-1/12'>Order Date</th>
                                <th className=' text-sm font-bold w-2/12'>Shipment Details</th>
                                <th className=' text-sm font-bold w-2/12'>Product Details</th>
                                <th className=' text-sm font-bold w-1/12'>Consignee Details</th>
                                <th className=' text-sm font-bold w-2/12'>Weight & Dimensions</th>
                                <th className=' text-sm font-bold w-1/12'>Shipment Status</th>
                                <th className=' text-sm font-bold w-1/12'>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {currentItems.map((element) => {
                                return <AllOrdersItems key={element._id} data={element} onCheckboxChange={handleCheckboxChange} isMasterChecked={masterChecked} selectedAwbs={selectedAwbs} />
                            })}
                        </tbody>
                    </table>
                </div>
                <div className='mb-10 flex items-center flex-col mt-5'>
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={
                            <div className='flex flex-row items-center'>
                                <h3 className='mx-2'>Next</h3>
                                <i className="fa-solid fa-arrow-right"></i>

                            </div>
                        }
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={3}
                        pageCount={pageCount}
                        previousLabel={
                            <div className='flex flex-row items-center'>
                                <i className="fa-solid fa-arrow-left"></i>
                                <h3 className='mx-2'>Prev</h3>
                            </div>
                        }

                        renderOnZeroPageCount={null}
                        containerClassName='flex flex-row items-center'
                        pageClassName='mx-1'
                        pageLinkClassName='px-3 border-blue-500 border rounded-2xl hover:bg-blue-500 hover:text-white'
                        activeLinkClassName='bg-blue-500 text-white'
                        previousClassName='bg-blue-500 cursor-pointer rounded-2xl text-white px-2'
                        nextClassName='bg-blue-500 cursor-pointer rounded-2xl text-white px-2'
                    />
                </div>
            </div>
        </React.Fragment>
    )
}

export default AllOrders
